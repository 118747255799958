import React, { useEffect, useState } from "react"
import Rodal from "rodal"
import { Wrapper } from "./style"
import "rodal/lib/rodal.css"

import PersonalInfo from "./PersonalInfo/PersonalInfo"

const RegisterModal = ({ visible, onClose }) => {
  const [positionScroll, setPositionScroll] = useState(null)

  useEffect(() => {
    if (visible) {
      setPositionScroll(window.pageYOffset)

      setTimeout(() => {
        document.documentElement.style.overflowY = "scroll"
        document.body.style.top = `-${positionScroll}px`
        document.body.style.left = `0px`
        document.body.style.right = `0px`
        document.body.style.bottom = `0px`
      }, 0)

      setTimeout(() => {
        document.body.style.position = `fixed`
      }, 0)
    } else {
      document.body.style.position = ""
      document.documentElement.style.overflowY = "auto"
      window.scrollTo(0, positionScroll)
    }
  }, [visible, positionScroll])

  return (
    <Wrapper>
      {visible && (
        <Rodal
          enterAnimation={"slideUp"}
          leaveAnimation={"fade"}
          duration={300}
          visible={visible}
          onClose={onClose}
        >
          <PersonalInfo />
        </Rodal>
      )}
    </Wrapper>
  )
}

export default RegisterModal
