import React from "react"
import { Wrapper } from "./style"

const InputUnderline = ({ reviewData, stateData, index }) => {
  return (
    <Wrapper sizeItem={reviewData.fullRow}>
      <h4>{stateData[index].value}</h4>
      <h5>{reviewData.field}</h5>
    </Wrapper>
  )
}

export default InputUnderline
