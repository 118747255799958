import styled from "styled-components"

export const Wrapper = styled.div``

export const ListEjemplo = styled.ul``

export const EjemploTitulo = styled.h1`
  color: ${props => props.theme.neutralDark};
`

export const LogoWrap = styled.div`
  img {
    height: ${props => (props.customSize === true ? "24px" : "20px")};
    margin: ${props => (props.customSize ? "45px" : "32px")};
  }
`

export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 4fr;
  align-items: center;

  height: auto;
  background-color: ${props => props.theme.neutralSilver};

  @media (max-width: 1199px) {
    grid-template-columns: 0.4fr 4fr;
  }

  

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    grid-template-rows: 64px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    z-index: 10;
    box-shadow: ${props => props.theme.boxShadow};
    background-color: white;
  }

  > div {
    &:first-child {
      background-color: ${props =>
        props.page !== true ? "transparent" : "transparent"};
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 48px;

      @media (max-width: 1199px) {
        width: auto;
      }

      @media (max-width: 991px) {
        padding-left: 20px;
        height: 65px;
        margin-top: 0;

        img {
          max-width: 160px;
        }
      }
    }
  }
`
