import React from "react"

const getViewBox = name => {
  switch (name) {
    case "arrow":
      return "0 0 9 7"
    case "arrowRight":
      return "0 0 35 16"
    case "location":
      return "0 0 12 16"
    case "phone":
      return "0 0 16 16"
    case "email":
      return "0 0 16 14"
    case "facebook":
      return "0 0 16 16"
    case "linkedin":
      return "0 0 16 16"
    case "twitter":
      return "0 0 16 16"
    case "youtube":
      return "0 0 16 16"
    case "m":
      return "0 0 76 48"
    case "i":
      return "0 0 7 48"
    case "n":
      return "0 0 58 48"
    case "d":
      return "0 0 61 48"
    case "calendar":
      return "0 0 24 24"
    case "clock":
      return "0 0 16 17"
    case "smallArrowRight":
      return "0 0 16 16"
    case "tick":
      return "0 0 18 19"
    default:
      return "0 0 32 32"
  }
}

const getPath = (name, props) => {
  switch (name) {
    case "arrow":
      return (
        <path
          {...props}
          d="M7.46482 0H1.86852C1.06982 0 0.59343 0.890145 1.03647 1.5547L3.83462 5.75192C4.23044 6.34566 5.10289 6.34566 5.49872 5.75192L8.29687 1.5547C8.7399 0.890145 8.26351 0 7.46482 0Z"
        />
      )
    case "arrowRight":
      return (
        <path
          {...props}
          d="M34.7071 8.70711C35.0976 8.31658 35.0976 7.68342 34.7071 7.29289L28.3431 0.928932C27.9526 0.538408 27.3195 0.538408 26.9289 0.928932C26.5384 1.31946 26.5384 1.95262 26.9289 2.34315L32.5858 8L26.9289 13.6569C26.5384 14.0474 26.5384 14.6805 26.9289 15.0711C27.3195 15.4616 27.9526 15.4616 28.3431 15.0711L34.7071 8.70711ZM0 9H34V7H0V9Z"
        />
      )
    case "location":
      return (
        <path
          {...props}
          d="M2.46447 2.46447C3.40215 1.52678 4.67392 1 6 1C7.32608 1 8.59785 1.52678 9.53553 2.46447C10.4732 3.40215 11 4.67392 11 6C11 8.07476 9.71686 10.3357 8.35165 12.1353C7.67856 13.0226 7.00432 13.7736 6.49805 14.3028C6.3036 14.5061 6.13435 14.6763 6 14.8084C5.86565 14.6763 5.6964 14.5061 5.50195 14.3028C4.99568 13.7736 4.32144 13.0226 3.64835 12.1353C2.28314 10.3357 1 8.07476 1 6C1 4.67392 1.52678 3.40215 2.46447 2.46447ZM5.66344 15.8698C5.66356 15.8699 5.66366 15.87 6 15.5L5.66366 15.87C5.85437 16.0433 6.14563 16.0433 6.33634 15.87L6 15.5C6.33634 15.87 6.33644 15.8699 6.33656 15.8698L6.33691 15.8694L6.33799 15.8685L6.34163 15.8651L6.35476 15.8531L6.40352 15.8077C6.44568 15.7682 6.50674 15.7105 6.584 15.6357C6.73848 15.4862 6.95792 15.2688 7.2207 14.994C7.74568 14.4452 8.44644 13.6649 9.14835 12.7397C10.5331 10.9143 12 8.42524 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 8.42524 1.46686 10.9143 2.85165 12.7397C3.55356 13.6649 4.25432 14.4452 4.7793 14.994C5.04208 15.2688 5.26152 15.4862 5.416 15.6357C5.49326 15.7105 5.55432 15.7682 5.59648 15.8077L5.64524 15.8531L5.65837 15.8651L5.66201 15.8685L5.66309 15.8694L5.66344 15.8698ZM4 6C4 4.89543 4.89543 4 6 4C7.10457 4 8 4.89543 8 6C8 7.10457 7.10457 8 6 8C4.89543 8 4 7.10457 4 6ZM6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3Z"
        />
      )
    case "phone":
      return (
        <path
          {...props}
          d="M12 10L10 12L4 6L6 4L3 1L1 3C1 9.627 6.373 15 13 15L15 13L12 10Z"
        />
      )
    case "email":
      return (
        <path
          {...props}
          d="M1.0001 1.5C1.0001 1.22414 1.22424 1 1.5001 1H14.5001C14.776 1 15.0001 1.22414 15.0001 1.5V3.7L8.0001 7.43333L1.0001 3.7V1.5ZM9.86877e-05 3.98965C-3.30939e-05 3.99627 -3.26977e-05 4.00288 9.86877e-05 4.00949V12.5C9.86877e-05 13.3281 0.671956 14 1.5001 14H14.5001C15.3282 14 16.0001 13.3281 16.0001 12.5V4.00945C16.0002 4.00287 16.0002 3.99628 16.0001 3.98969V1.5C16.0001 0.671858 15.3282 0 14.5001 0H1.5001C0.671956 0 9.86877e-05 0.671858 9.86877e-05 1.5V3.98965ZM15.0001 4.83333V12.5C15.0001 12.7759 14.776 13 14.5001 13H1.5001C1.22424 13 1.0001 12.7759 1.0001 12.5V4.83333L7.7648 8.44118C7.91186 8.51961 8.08833 8.51961 8.23539 8.44118L15.0001 4.83333Z"
        />
      )
    case "facebook":
      return (
        <path
          {...props}
          d="M2.34375 16H7.5625V10.3125H5.6875V8.4375H7.5625V6.09375C7.5625 4.80139 8.61389 3.75 9.90625 3.75H12.25V5.625H10.375C9.85803 5.625 9.4375 6.04553 9.4375 6.5625V8.4375H12.1654L11.8529 10.3125H9.4375V16H13.6562C14.9486 16 16 14.9486 16 13.6562V2.34375C16 1.05139 14.9486 0 13.6562 0H2.34375C1.05139 0 0 1.05139 0 2.34375V13.6562C0 14.9486 1.05139 16 2.34375 16ZM0.9375 2.34375C0.9375 1.56836 1.56836 0.9375 2.34375 0.9375H13.6562C14.4316 0.9375 15.0625 1.56836 15.0625 2.34375V13.6562C15.0625 14.4316 14.4316 15.0625 13.6562 15.0625H10.375V11.25H12.6471L13.2721 7.5H10.375V6.5625H13.1875V2.8125H9.90625C8.09692 2.8125 6.625 4.28442 6.625 6.09375V7.5H4.75V11.25H6.625V15.0625H2.34375C1.56836 15.0625 0.9375 14.4316 0.9375 13.6562V2.34375Z"
        />
      )
    case "linkedin":
      return (
        <path
          {...props}
          d="M2.34375 0H13.6562C14.9486 0 16 1.05139 16 2.34375V13.6562C16 14.9486 14.9486 16 13.6562 16H2.34375C1.05139 16 0 14.9486 0 13.6562V2.34375C0 1.05139 1.05139 0 2.34375 0ZM13.6562 15.0625C14.4316 15.0625 15.0625 14.4316 15.0625 13.6562V2.34375C15.0625 1.56836 14.4316 0.9375 13.6562 0.9375H2.34375C1.56836 0.9375 0.9375 1.56836 0.9375 2.34375V13.6562C0.9375 14.4316 1.56836 15.0625 2.34375 15.0625H13.6562ZM5.65625 13.1875H2.84375V6.5625H5.65625V13.1875ZM4.71875 7.5H3.78125V12.25H4.71875V7.5ZM10.3464 6.5625H10.3438C10.0197 6.5625 9.70398 6.6167 9.40625 6.72192V6.5625H6.59375V13.1875H9.40625V9.84375C9.40625 9.58533 9.61658 9.375 9.875 9.375C10.1334 9.375 10.3438 9.58533 10.3438 9.84375V13.1875H13.1562V9.52148C13.1562 8.00439 11.9255 6.56396 10.3464 6.5625ZM12.2188 12.25H11.2812V9.84375C11.2812 9.06836 10.6504 8.4375 9.875 8.4375C9.09961 8.4375 8.46887 9.06836 8.46875 9.84363V12.25H7.53125V7.5H8.46875V8.43811L9.21899 7.87463C9.54529 7.62952 9.9342 7.5 10.3438 7.5H10.3456C11.3434 7.50098 12.2188 8.44556 12.2188 9.52148V12.25ZM5.65625 5.625H2.84375V2.8125H5.65625V5.625ZM4.71875 3.75H3.78125V4.6875H4.71875V3.75Z"
        />
      )
    case "twitter":
      return (
        <path
          {...props}
          d="M16 3.21764C14.9935 3.26403 15.0155 3.26024 14.9017 3.26976L15.4978 1.56749C15.4978 1.56749 13.6372 2.25194 13.1655 2.37438C11.9268 1.26097 10.0854 1.21178 8.76904 2.02086C7.69104 2.68358 7.11243 3.82164 7.30542 5.15184C5.2074 4.86071 3.4397 3.8662 2.04407 2.18969L1.60266 1.65953L1.27283 2.26525C0.855957 3.03063 0.717529 3.90575 0.882934 4.72936C0.950805 5.06725 1.06616 5.38951 1.22595 5.69127L0.84729 5.54454L0.802368 6.17723C0.756836 6.81993 0.970825 7.57018 1.37488 8.18395C1.48865 8.3568 1.63513 8.54662 1.8208 8.73388L1.62512 8.70385L1.86389 9.42882C2.17773 10.3813 2.82959 11.1183 3.67554 11.5279C2.83069 11.8865 2.14819 12.1152 1.02637 12.4842L0 12.8216L0.947998 13.34C1.30945 13.5376 2.58667 14.1976 3.84863 14.3956C6.65381 14.8356 9.81213 14.4773 11.9384 12.5624C13.7292 10.9495 14.3169 8.65514 14.1948 6.26757C14.1764 5.90612 14.2754 5.56115 14.4736 5.29638C14.8707 4.76622 15.9976 3.22118 16 3.21764ZM13.724 4.73485C13.3948 5.17443 13.2299 5.73583 13.2594 6.31542C13.3826 8.72374 12.7273 10.5914 11.3116 11.8663C9.65796 13.3557 6.9906 13.9403 3.99377 13.4702C3.45105 13.3851 2.89014 13.1946 2.4259 13.0043C3.36645 12.6807 4.09277 12.3922 5.26575 11.837L6.9032 11.062L5.09534 10.9463C4.22937 10.8909 3.5083 10.4712 3.06506 9.78978C3.30041 9.77611 3.52734 9.73803 3.7533 9.67504L5.47754 9.19481L3.73889 8.76903C2.89404 8.56212 2.41248 8.05663 2.15723 7.66893C1.98962 7.41417 1.88013 7.15319 1.81445 6.91259C1.98877 6.95934 2.19202 6.99266 2.51977 7.02514L4.12903 7.18431L2.854 6.18969C1.9353 5.47313 1.56714 4.3966 1.83704 3.36168C4.70422 6.33556 8.07068 6.11205 8.40954 6.19066C8.33496 5.46618 8.33301 5.46447 8.31347 5.3955C7.87952 3.86144 8.83056 3.08251 9.25952 2.81884C10.1555 2.26818 11.5778 2.18517 12.5629 3.0924C12.7756 3.2882 13.0634 3.36522 13.3326 3.29833C13.5743 3.23827 13.7727 3.17467 13.9669 3.10546L13.5626 4.26L14.0787 4.26036C13.9813 4.39098 13.8645 4.54735 13.724 4.73485Z"
        />
      )
    case "youtube":
      return (
        <path
          {...props}
          d="M13.6562 13.0938H2.34375C1.05139 13.0938 0 12.0424 0 10.75V3.25C0 1.95764 1.05139 0.90625 2.34375 0.90625H13.6562C14.9486 0.90625 16 1.95764 16 3.25V10.75C16 12.0424 14.9486 13.0938 13.6562 13.0938ZM2.34375 1.84375C1.56836 1.84375 0.9375 2.47461 0.9375 3.25V10.75C0.9375 11.5254 1.56836 12.1562 2.34375 12.1562H13.6562C14.4316 12.1562 15.0625 11.5254 15.0625 10.75V3.25C15.0625 2.47461 14.4316 1.84375 13.6562 1.84375H2.34375ZM5.65625 10.2314V3.85962L11.2969 6.99329L5.65625 10.2314ZM9.39062 7.00671L6.59375 5.45288V8.6123L9.39062 7.00671Z"
        />
      )
    case "m":
      return (
        <path
          {...props}
          d="M9.48147 0.352905L37.7037 42.6678L65.9444 0.352905H75.4258V47.1049H69.1666V7.06268L42.4444 47.1049H32.9629L6.25926 7.06268V47.1049H0V0.352905H9.48147Z"
        />
      )
    case "i":
      return (
        <path {...props} d="M6.25916 0.352905V47.1049H0V0.352905H6.25916Z" />
      )
    case "n":
      return (
        <path
          {...props}
          d="M9.48147 0.352905L51.2963 41.315V0.352905H57.5556V47.1049H48.0741L6.25927 6.12475V47.0868H0V0.352905H9.48147Z"
        />
      )
    case "d":
      return (
        <path
          {...props}
          d="M23.2592 0.352905C47.6296 0.352905 60.5741 8.46956 60.5741 23.7289C60.5741 38.9882 47.5556 47.1049 23.0556 47.1049H0V0.352905H23.2592ZM54.3334 23.7289C54.3334 12.077 43.4815 5.89027 23.0741 5.89027H6.2778V41.5675H23.0741C43.4815 41.5675 54.3334 35.3628 54.3334 23.7289Z"
        />
      )
    case "calendar":
      return (
        <path
          {...props}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.25 0.75C7.25 0.473858 7.02614 0.25 6.75 0.25C6.47386 0.25 6.25 0.473858 6.25 0.75V3.25H3.75C2.64586 3.25 1.75 4.14586 1.75 5.25V8.25V21.75C1.75 22.8541 2.64586 23.75 3.75 23.75H21.75C22.8541 23.75 23.75 22.8541 23.75 21.75V8.25V5.25C23.75 4.14586 22.8541 3.25 21.75 3.25H19.25V0.75C19.25 0.473858 19.0261 0.25 18.75 0.25C18.4739 0.25 18.25 0.473858 18.25 0.75V3.25H13.25V0.75C13.25 0.473858 13.0261 0.25 12.75 0.25C12.4739 0.25 12.25 0.473858 12.25 0.75V3.25H7.25V0.75ZM12.75 4.25H6.75H3.75C3.19814 4.25 2.75 4.69814 2.75 5.25V7.75H22.75V5.25C22.75 4.69814 22.3019 4.25 21.75 4.25H18.75H12.75ZM22.75 21.75V8.75H2.75V21.75C2.75 22.3019 3.19814 22.75 3.75 22.75H21.75C22.3019 22.75 22.75 22.3019 22.75 21.75Z"
        />
      )
    case "clock":
      return (
        <path
          {...props}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 8C1 4.41015 3.91015 1.5 7.5 1.5C11.0899 1.5 14 4.41015 14 8C14 11.5899 11.0899 14.5 7.5 14.5C3.91015 14.5 1 11.5899 1 8ZM7.5 0.5C3.35786 0.5 0 3.85786 0 8C0 12.1421 3.35786 15.5 7.5 15.5C11.6421 15.5 15 12.1421 15 8C15 3.85786 11.6421 0.5 7.5 0.5ZM8 4C8 3.72386 7.77614 3.5 7.5 3.5C7.22386 3.5 7 3.72386 7 4V8C7 8.27614 7.22386 8.5 7.5 8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8V4Z"
        />
      )
    case "smallArrowRight":
      return (
        <path
          {...props}
          d="M4 4.86856L4 10.4649C4 11.2636 4.89015 11.7399 5.5547 11.2969L9.75192 8.49876C10.3457 8.10294 10.3457 7.23048 9.75192 6.83466L5.5547 4.03651C4.89015 3.59347 4 4.06986 4 4.86856Z"
          fill={props.fill || "url(#orangeGradient)"}
        />
      )
    case "dragUpload":
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3716 0.415518C12.2768 0.310161 12.1417 0.25 12 0.25C11.8583 0.25 11.7232 0.310161 11.6284 0.415518L4.87835 7.91552C4.74626 8.06228 4.71292 8.27304 4.79325 8.45342C4.87358 8.63379 5.05254 8.75 5.25 8.75H9.25V17.25C9.25 17.5261 9.47386 17.75 9.75 17.75H14.25C14.5261 17.75 14.75 17.5261 14.75 17.25V8.75H18.75C18.9475 8.75 19.1264 8.63379 19.2067 8.45342C19.2871 8.27304 19.2537 8.06228 19.1216 7.91552L12.3716 0.415518ZM9.75 7.75H6.37268L12 1.49742L17.6273 7.75H14.25C13.9739 7.75 13.75 7.97386 13.75 8.25V16.75H10.25V8.25C10.25 7.97386 10.0261 7.75 9.75 7.75ZM1.25 15.75C1.25 15.4739 1.02614 15.25 0.75 15.25C0.473858 15.25 0.25 15.4739 0.25 15.75V21.75C0.25 22.8541 1.14586 23.75 2.25 23.75H21.75C22.8541 23.75 23.75 22.8541 23.75 21.75V15.75C23.75 15.4739 23.5261 15.25 23.25 15.25C22.9739 15.25 22.75 15.4739 22.75 15.75V21.75C22.75 22.3019 22.3019 22.75 21.75 22.75H2.25C1.69814 22.75 1.25 22.3019 1.25 21.75V15.75Z"
          fill="#B0C9CB"
        />
      )
    case "play":
      return (
        <path
          d="M0.75 22.5225V1.40909C0.75 0.602327 1.65618 0.127658 2.31939 0.587025L17.0988 10.8238C17.6629 11.2146 17.6748 12.0445 17.1221 12.4513L2.34272 23.3279C1.68239 23.8138 0.75 23.3423 0.75 22.5225Z"
          fill="white"
        />
      )
    case "tick":
      return (
        <path
          stroke="#F38B97"
          strokeWidth="3"
          {...props}
          fill="transparent"
          d="M2.38676 10.8357L7.20548 15.0834L15.8498 5.59991"
        />
      )
    default:
      return <path />
  }
}

const SVGIcon = ({
  name = "",
  style = {},
  fill = "",
  viewBox = "",
  width = "100%",
  className = "",
  height = "100%",
  stroke,
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox || getViewBox(name)}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {getPath(name, { fill, stroke })}
    <defs>
      <linearGradient id="orangeGradient">
        <stop offset="0%" stopColor="#F38B97" />
        <stop offset="100%" stopColor="#F4904D" />
      </linearGradient>
    </defs>
  </svg>
)

export default SVGIcon
