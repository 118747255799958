import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import { styleTheme } from "../utils"
import "normalize.css"
import Normalize from "../utils/GlobalStyles"
import "./layout.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// AOS
import AOS from "aos"
import "aos/dist/aos.css"

import Header from "../components/Header/Header"
import Footer from "../components/Footer"
import { globalHistory } from "@reach/router"

// Image
import OGImage from "../images/og-image.png"

const Layout = ({ children }) => {
  const [current, setCurrent] = useState(null)
  const path = globalHistory.location.pathname

  const pathName = path => {
    if (path) {
      if (path === "/") {
        setCurrent(true)
      } else {
        setCurrent(false)
      }
    }
  }

  useEffect(() => {
    pathName(path)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <ThemeProvider theme={styleTheme}>
      <Helmet>
        <meta property="og:image" content={OGImage} />
      </Helmet>
      <Normalize />
      <Header currentLink={path} page={path ? current : null} />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
