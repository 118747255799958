import { createGlobalStyle } from "styled-components"
import styledNormalize from "styled-normalize"

const GlobalStyle = createGlobalStyle`

${styledNormalize}
 :root {
   //fonts



}
body {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  .slick-slide, .slick-slide * { outline: none !important; }
}

html{
  overflow-x: hidden;
}

.rodal-slideUp-enter{
  animation:rodal-slideUp-enter both ease-out
}

main{
  overflow:hidden;
}



* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  transition: 0.5s ease all;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin-bottom: 24px;
}

.rodal-dialog {
  width: 100% !important;
  height: 100% !important;
  max-width: 1000px;
  max-height: 550px;
  background-color: transparent;
  box-shadow: none;
}
.rodal-close {
  top: 10px;
  right: -30px;
  width: 32px;
  height: 32px;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  &:after,
  &:before {
    border-radius: 0;
    outline: 0 !important;
    background-color: ${props => props.theme.neutralSilver};
    opacity: 0.8;
  }
  &:focus {
    outline: 0;
  }
}
.rodal-dialog iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
`

export default GlobalStyle
