import React from "react"
import LogoUrl from "../../../images/logo.svg"

const Logo = ({width}) => {
  return (
    <img src={LogoUrl} width={width} alt="Lymemind" />
  )
}

export default Logo
