import React from "react"
import { Wrapper, BlobItem, AnimatedDiv } from "./style"

const Blob = ({ toTop, toRight, toLeft, customWidth, customHeight, bg, delay, noMobile }) => {
  return (
    <AnimatedDiv
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay={delay}
      toTop={toTop}
      toRight={toRight}
      toLeft={toLeft}
    >
      <Wrapper>
        <BlobItem
          customWidth={customWidth}
          bg={bg}
          customHeight={customHeight}
          noMobile={noMobile}
        />
      </Wrapper>
    </AnimatedDiv>
  )
}

export default Blob
