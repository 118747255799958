import styled from "styled-components"

import { XL } from "../../../utils/constants"

export const DropMenuWrap = styled.div`
/*   opacity: ${props => (props.isOpen === true ? "1" : "0")};
  visibility: ${props => (props.isOpen === true ? "visible" : "hidden")};
  transition: ${props => props.theme.defaultTransition()};



  &:hover {
    visibility: visible;
    opacity: 1;
  } */
`

export const Nav = styled.nav`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-left: 48px;
  padding-right: 65px;

  height: 100%;

  > div {
    height: 100%;
  }

  @media (max-width: ${XL}) {
    justify-content: center;
    padding-left: 0;
  padding-right: 0
  ;
  }
`

export const MenuItem = styled.div`
  a,
  span {
    color: ${props => props.theme.neutralDark};
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    padding: 0 16px;
    opacity: 0.6;
    transition: all 0.3s cubic-bezier(0.15, 0.82, 1, 1) 0s;
    position: relative;
    display: inline-block;
    &.with-more {
      margin-right: 7px;
    }
    &:hover {
      opacity: 1;
    }

    @media (max-width: ${XL}) {
      padding: 0 10px;
    }
  }

  a {
    align-items: center;
    display: flex;
    height: 100%;
  }

  &:after {
    content: "";
    width: calc(100% - 16px);
    height: 4px;
    background: linear-gradient(104.79deg, #f38b97 20.01%, #f4904d 75.82%);
    display: block;
    position: relative;
    top: -5px;
    left: 10px;
    transition: transform 0.4s ease-out;
    transform-origin: right;
    ${props => (props.active ? "transform: scaleX(1)" : "transform: scaleX(0)")}
  }

  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
`

export const MenuDropdown = styled.div`
  position: absolute;
`

export const NavItem = styled.div`
  position: relative;
  display: flex;
  padding-right: 16px;
  height: 100%;
  align-items: center;

  cursor: pointer;
  svg {
    fill: url(#orangeGradient);
    position: relative;
    top: 1px;
  }
  span {
    padding-right: 0 !important;
  }
  /*&:hover {
    &::after {
      transform: scaleX(1);
    }
  }*/
  &.open {
    &::after {
      transform: scaleX(1);
    }
  }
`

export const EventSubmenu = styled.div`
  position: absolute;
  top: auto;
  background: white;
  box-shadow: ${props => props.theme.boxShadow};

  width: calc(100% - 575px);
  height: 344px;
  margin-left: -64px;
  padding-left: -64px;
  display: flex;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: height 300ms ease;
  z-index: 5;

  left: 637px;
  justify-content: center;

  opacity: ${props => (props.isOpen === true ? "1" : "0")};
  visibility: ${props => (props.isOpen === true ? "visible" : "hidden")};
  transition: ${props => props.theme.defaultTransition()};

  &:hover {
    visibility: visible;
    opacity: 1;
  }

  a {
    display: block;
    padding: 25px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      text-align: center;
      display: inline-block;
      line-height: normal;
      opacity: 1;
    }
  }
  button {
    width: fit-content;
  }
  & > div {
    padding: 40px 67px 40px 80px;
    border-right: 1px solid transparent;
    display: flex;
    flex-direction: column;
    &:first-child {
      border-color: ${props => props.theme.borderColor};
      ul {
        li {
          line-height: 31px;
          svg {
            transform: translate(-5px, 0) rotate(0deg);
          }
          a {
            font-size: 24px;
            justify-content: flex-start;

            &:hover {
              svg {
                transform: translate(0px, 0) rotate(0deg);
              }
            }
          }
        }
      }
    }
    &:last-child {
      ul {
        li {
          a {
            svg {
              fill: ${props => props.theme.neutralGreen};
            }
          }
        }
      }
    }
  }
  ul {
    margin: 40px 0;
    li {
      line-height: 24px;
      padding: 8px 0;
      svg {
        fill: url(#orangeGradient);
        margin-left: 13px;
        opacity: 0;
        transition: all 0.5s ease 0s;
        transform: translate(-5px, 0) rotate(-90deg);
      }
      a {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        padding: 0;
        display: flex;
        align-items: center;

        &:hover {
          svg {
            opacity: 1;
            transform: translate(0px, 0) rotate(-90deg);
          }
        }
      }
    }
  }

  @media (max-width: ${XL}) {
    width: auto;
    left: 45%;
    right: 0;
    z-index: 5;

    > div {
      padding: 40px;
    }
  }
`

export const BtnContainer = styled.div`
  a {
    padding: 20px 16px;
    opacity: 1;
    span {
    }
  }
`
