import styled from "styled-components"

import { LG } from "../../../utils/constants"

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 2px 6px 48px rgba(243, 139, 151, 0.27);
  border-top-left-radius: 8px;
  border-top-right-radius: 12px;

  display: ${props => (props.hide ? "none" : "block")};

  @media (max-width: ${LG}) {
    box-shadow: none;
  }
`

export const TabContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
`

export const Tab = styled.div`
  padding: 35px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;

  color: ${props => props.theme.neutralDark};
  opacity: ${props => (props.tabActive === props.index ? 1 : 0.4)};
  position: relative;

  cursor:pointer;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 4px;
    background: ${props => props.theme.orangeGradient};

    opacity: ${props => (props.tabActive === props.index ? 1 : 0)};
  }

  span {
    margin-right: 10px;
  }
`

export const MobileTabsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;

  position: relative;
  z-index: 5;

  grid-template-rows: 100px;
  box-shadow: 2px 6px 48px rgba(243, 139, 151, 0.27);
`

export const MobileTab = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props =>
    props.tabActive === props.index ? props.theme.orangeGradient : "white"};
  color: ${props => (props.tabActive === props.index ? "white" : "#0035417a")};

  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  position: relative;
  z-index: 5;
`

export const CaretWrap = styled.div`
  position: relative;
  left: 50%;
  transform: translate(-30px, 0);
  width: 50px;
`

export const CaretBottom = styled.div`
  width: 60px;
  height: 60px;
  position: relative;
  bottom: 55px;
  display: block;
  background: ${props =>
    props.tabActive === props.index ? props.theme.orangeGradient : "transparent"};
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 0;
`

export const MobileTitleWrap = styled.div`
  background-color: ${props => props.theme.neutralSilver};
  text-align: center;
  padding-top: 50px;

  @media(max-width:${LG}){
    text-align:left;
    padding-left:30px;
  }
`

export const MobileTitle = styled.div`
  display: ${props => (props.tabActive === props.index ? "block" : "none")};
  font-weight: 500;
  font-size: 22px;
  line-height: 31px;
  color: ${props => props.theme.neutralDark};
`
