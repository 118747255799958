import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { menuItems } from "../../utils/constants"
import { HeaderWrapper, LogoWrap } from "./style"
import Logo from "../common/Logo"
import Menu from "../common/Menu"
import MobileMenu from "../common/MobileMenu/MobileMenu"
import { useMediaQuery } from "react-responsive"

const Header = ({ page, currentLink }) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })
  const isDesktop = useMediaQuery({
    query: "(min-device-width: 992px)",
  })

  const [active, setActive] = useState(-1)

  useEffect(() => {
    menuItems.forEach((item, i) => {
      if (
        currentLink.replace("/", "").replace("/", "") ===
        item.path.replace("/", "")
      ) {
        return setActive(i)
      } else {
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HeaderWrapper page={false}>
      <LogoWrap customSize={false}>
        <Link to="/">
          <Logo />
        </Link>
      </LogoWrap>

      {isDesktop && (
        <Menu currentLink={currentLink} active={active} items={menuItems} />
      )}

      {isTabletOrMobileDevice && <MobileMenu items={menuItems} />}
    </HeaderWrapper>
  )
}

export default Header
