import React, { useState, useEffect } from "react"
import { useStaticQuery } from "gatsby"
import moment from "moment"
import {
  Wrapper,
  ToggleMenu,
  Hamburguer,
  MobileNav,
  LogoMobile,
  Close,
  SubMenuWrapper,
  SubMenuBg,
} from "./style"
import { Link } from "gatsby"

import LogoWhite from "../../../images/logo-white.svg"
import CloseButton from "../../../images/close-button.png"
import WhiteCaret from "../../../images/white-caret.svg"

import SubMenu from "./SubMenu/SubMenu"

const MobileMenu = ({ items }) => {
  const {
    allWordpressWpUpcomingEvents: { edges },
  } = useStaticQuery(graphql`
    query MenuQueryMobile {
      allWordpressWpUpcomingEvents {
        edges {
          node {
            acf {
              event_information {
                events_agenda
                show_event_in_menu
                is_registration_open
              }
            }
          }
        }
      }
    }
  `)

  const sortedDates = edges
    .slice()
    .sort(
      (a, b) =>
        parseInt(moment(b.node.date).format("x")) +
        parseInt(moment(a.node.date).format("x"))
    )

  const lastEdge = sortedDates[0]

  const [isOpen, setIsOpen] = useState(false)
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  MobileMenu.handleClickOutside = () => setIsOpen(false)

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
      document.documentElement.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
      document.documentElement.style.overflow = "auto"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Wrapper>
      <ToggleMenu>
        <Hamburguer
          isOpen={isOpen}
          menuClicked={() => toggle()}
          width={22.5}
          height={15}
          strokeWidth={2}
          rotate={0}
          color="white"
          borderRadius={2}
          animationDuration={0.5}
        />
      </ToggleMenu>

      <MobileNav isOpen={isOpen}>
        <div>
          <Link to="/">
            <LogoMobile src={LogoWhite} isOpen={isOpen} alt="Lymemind" />
          </Link>
          {items.map((item, i) => (
            <li key={i}>
              {item.label === "Conference" ? (
                <SubMenuWrapper isOpen={isOpenSubMenu}>
                  <SubMenuBg
                    isOpen={isOpenSubMenu}
                    info={lastEdge.node.acf.event_information}
                  />
                  <button onClick={() => setIsOpenSubMenu(!isOpenSubMenu)}>
                    Conference
                    <span>
                      <img alt="white-caret" src={WhiteCaret} />
                    </span>
                  </button>
                  <SubMenu
                    isOpen={isOpenSubMenu}
                    info={lastEdge.node.acf.event_information}
                  />
                </SubMenuWrapper>
              ) : (
                <Link to={item.path.toLowerCase()}>{item.label}</Link>
              )}
            </li>
          ))}
          <Close
            src={CloseButton}
            alt="close"
            isOpen={isOpen}
            onClick={() => toggle()}
          />
        </div>
      </MobileNav>
    </Wrapper>
  )
}

export default MobileMenu
