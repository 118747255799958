import React, { useState, Fragment } from "react"
import { useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import { EventSubmenu, BtnContainer, DropMenuWrap } from "./style"
import { MainH5, SecondaryBtn, SecondaryButtonLink } from "../../../utils/GlobalComponents"
import SVGIcon from "../SVGIcon"
import RegisterModal from "../../RegisterModal/RegisterModal"
import moment from "moment"

const EventDropdown = ({ isOpen, info }) => {
  const {
    allWordpressWpPastEvents: { edges },
  } = useStaticQuery(graphql`
    query PastEventsMenu {
      allWordpressWpPastEvents(filter: {wordpress_id: {ne: 1627}}) {
        edges {
          node {
            title
          }
        }
      }
    }
  `)
  const [modalOpen, setModalOpen] = useState(false)

  const handleModalOpen = () => {
    setModalOpen(!modalOpen)
  }
  return (
    <Fragment>
      <DropMenuWrap isOpen={isOpen}>
        <RegisterModal visible={modalOpen} onClose={() => handleModalOpen()} />
        <EventSubmenu isOpen={isOpen}>
          {info.is_registration_open && (
            <div>
              <MainH5>
                <strong>Upcoming event</strong>
              </MainH5>
              <ul>
                <li>
                  <Link to="/upcoming-event">
                    2022 Event Details <SVGIcon name="arrowRight" width={35} />
                  </Link>
                </li>
                <li>
                  {info.events_agenda !== null && (
                    <Link to="/agenda">
                      View Agenda <SVGIcon name="arrowRight" width={35} />
                    </Link>
                  )}
                </li>
              </ul>
              <BtnContainer>
                {/* <SecondaryBtn
                  as="button"
                  onClick={() => handleModalOpen(true)}
                  to="/"
                  secondary={"A"}
                >
                  <span>Register for the Event</span>
                </SecondaryBtn> */}
                <SecondaryButtonLink
                  href="https://lyme-mind-2022.open-exchange.net/welcome"
                  target="_blank" rel="noopener noreferrer"
                  secondary={"A"}
                >
                  <span>Register for the Event</span>
                </SecondaryButtonLink>
              </BtnContainer>
            </div>
          )}

          <div>
            <MainH5>
              <strong>Past events</strong>
            </MainH5>
            <ul>
              {edges &&
                edges.sort((a, b) => (a.node.title > b.node.title) ? -1 : 1).map((confLink, i) => (
                  <li key={i}>
             
                    <Link to={`/past-event-${confLink.node.title}`}>
                      {confLink.node.title} Conference{" "}
                      <SVGIcon name="arrow" width={9} />
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </EventSubmenu>
      </DropMenuWrap>
    </Fragment>
  )
}

export default EventDropdown
