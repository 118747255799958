import styled from "styled-components"
import { Link } from "gatsby"

import { textLinkAnimation } from "../utils/KeyFrames"
import QuestionMark from "../images/questionMark.svg"
import InputCaretNext from "../images/input-caret.svg"
import InputCaretBack from "../images/input-back-caret.svg"

export const DefaultWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`

export const MainH2 = styled.h2`
  font-weight: 500;
  font-size: 48px;
  line-height: 53px;
  background: ${props =>
    props.gradient ? props.gradient : props.theme.orangeGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: ${props => (props.maxWidth ? props.maxWidth : "400px")};

  @media (max-width: 991px) {
    font-size: 32px;
    line-height: 32px;
  }
`

export const MainH3 = styled.h3`
  color: ${props => (props.color ? props.color : props.theme.navy)};
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;
  max-width: ${props => (props.maxWidth ? props.maxWidth : "unset")};

  strong {
    font-weight: 500;
  }

  @media (max-width: 991px) {
    font-size: 22px;
    line-height: 31px;
  }
`
export const MainH4 = styled.h4`
  color: ${props => (props.color ? props.color : props.theme.neutralDark)};
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 31px;
  max-width: ${props => (props.maxWidth ? props.maxWidth : "unset")};

  strong {
    font-weight: 500;
  }
`

export const MainH5 = styled.h5`
  color: ${props => (props.color ? props.color : props.theme.neutralGreen)};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  max-width: ${props => (props.maxWidth ? props.maxWidth : "unset")};

  strong {
    font-weight: 500;
  }
`

export const MainParagraph = styled.p`
  color: ${props => (props.color ? props.color : props.theme.neutralDark)};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.01em;
`

export const BigParagraph = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: ${props => props.theme.navy};
`
export const TextLinkWrap = styled.div`
  width: max-content;
  cursor: pointer;
  background: ${props =>
    props.gradient === "green"
      ? props.theme.greenGradient
      : props.theme.orangeGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-mask-image: ${props =>
    props.gradient === "green"
      ? props.theme.greenGradient
      : props.theme.orangeGradient};

  position: relative;

  height: 50px;

  &:after {
    content: "";
    width: 100%;
    height: 4px;

    background: ${props =>
      props.gradient === "green"
        ? props.theme.greenGradient
        : props.theme.orangeGradient};
    display: block;
    margin-top: 4px;
    transition: ${props => props.theme.defaultTransition()};
    position: absolute;
  }
  &:hover {
    &:after {
      animation: ${textLinkAnimation} 1s ease-in 0s forwards;
    }
  }
`

export const MainTextLink = styled(Link)`
  /*  display: block; */

  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  /*   display: inline-block; */
  display: inline;
  max-width: max-content;
`
export const PrimaryBtn = styled(Link)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  border: 0;

  background: ${props =>
    props.primary === "A"
      ? props.theme.greenGradient
      : props.theme.orangeGradient};
  padding: 16px 32px;
  cursor: pointer;
height:58px;
  border-radius: 80px;
  box-shadow: ${props =>
    props.primary === "A"
      ? props.theme.boxShadowGreen
      : props.theme.boxShadowOrange};

  background-image: ${props =>
    props.primary === "A"
      ? props.theme.boxShadowGreen
      : props.theme.boxShadowOrange};

  -webkit-backface-visibility: hidden;
  z-index: 1;
  position: relative;
  top:2px;
  display:flex;
  align-items:center;
  justify-content:center;

  span {
    position: relative;
    line-height: 1.5;
    z-index: 3;
    text-align:center;
    display:inline-block;
  }

  &:before {
    content: "";
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border: 4px solid rgba(0, 81, 99, 0.1);
    /* margin-right: 12px; */
    border-radius: 80px;
    z-index: 0;
    opacity: 0.8;
  }

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.3em;
    background-image: ${props =>
      props.primary === "A"
        ? props.theme.greenGradientHover
        : props.theme.orangeGradientHover};
    transition: opacity 0.2s ease-out;
    z-index: 2;
    opacity: 0;
    border-radius: 80px;
  }

  &:hover {
    box-shadow: ${props =>
      props.primary === "A"
        ? "0px 6px 19px rgba(21, 233, 187, 0.57)"
        : "0px 6px 19px rgba(255, 194, 36, 0.47);"};

    &:before {
      border: 4px solid rgba(0, 81, 99, 0.1);
      opacity: 0.7;

/*       box-shadow: ${props =>
  props.primary === "A"
    ? "0px 6px 19px rgba(21, 233, 187, 0.57)"
    : "0px 6px 19px rgba(255, 194, 36, 0.47)"}; */
    }
    &:after {
      opacity: 1;
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
  }
`

export const PrimaryBtnExternal = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  border: 0;

  background: ${props =>
    props.primary === "A"
      ? props.theme.greenGradient
      : props.theme.orangeGradient};
  padding: 16px 32px;
  cursor: pointer;
height:58px;
  border-radius: 80px;
  box-shadow: ${props =>
    props.primary === "A"
      ? props.theme.boxShadowGreen
      : props.theme.boxShadowOrange};

  background-image: ${props =>
    props.primary === "A"
      ? props.theme.boxShadowGreen
      : props.theme.boxShadowOrange};

  -webkit-backface-visibility: hidden;
  z-index: 1;
  position: relative;
  top:2px;
  display:flex;
  align-items:center;
  justify-content:center;

  span {
    position: relative;
    line-height: 1.5;
    z-index: 3;
    text-align:center;
    display:inline-block;
  }

  &:before {
    content: "";
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border: 4px solid rgba(0, 81, 99, 0.1);
    /* margin-right: 12px; */
    border-radius: 80px;
    z-index: 0;
    opacity: 0.8;
  }

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.3em;
    background-image: ${props =>
      props.primary === "A"
        ? props.theme.greenGradientHover
        : props.theme.orangeGradientHover};
    transition: opacity 0.2s ease-out;
    z-index: 2;
    opacity: 0;
    border-radius: 80px;
  }

  &:hover {
    box-shadow: ${props =>
      props.primary === "A"
        ? "0px 6px 19px rgba(21, 233, 187, 0.57)"
        : "0px 6px 19px rgba(255, 194, 36, 0.47);"};

    &:before {
      border: 4px solid rgba(0, 81, 99, 0.1);
      opacity: 0.7;

/*       box-shadow: ${props =>
  props.primary === "A"
    ? "0px 6px 19px rgba(21, 233, 187, 0.57)"
    : "0px 6px 19px rgba(255, 194, 36, 0.47)"}; */
    }
    &:after {
      opacity: 1;
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
  }
`

export const SecondaryBtn = styled(Link)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  background: transparent;
  display: inline-block;
  padding: 16px 32px;
  cursor: pointer;

  transition: ${props => props.theme.defaultTransition()};

  border: 4px solid #eef3f4;
  border-radius: 80px;

  &:after {
    content: "";
    width: 14px;
    height: 14px;
    background: url(${InputCaretNext}) no-repeat center center;
    display: ${props => (props.next ? "inline-block" : "none")};
    vertical-align: middle;
    margin-left: ${props => (props.next ? "5px" : "0")};

    position: relative;
    top: -1px;
  }

  &:before {
    content: "";
    width: 14px;
    height: 14px;
    background: url(${InputCaretBack}) no-repeat center center;
    display: ${props => (props.back ? "inline-block" : "none")};
    vertical-align: middle;
    margin-right: ${props => (props.back ? "5px" : "0")};
    position: relative;
    top: -1px;
  }

  &:hover {
    background-color: #eef3f4;
    border-color: #eef3f4;
  }

  span {
    background: ${props =>
      props.secondary === "A"
        ? "linear-gradient(90deg, #0F7F90 -8.75%, #00B08A 113.12%)"
        : "linear-gradient(128.61deg, #F38B97 20.01%, #F4904D 75.82%)"};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 991px) {
    width: 100%;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
  }
`

export const SecondaryButtonLink = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  background: transparent;
  display: inline-block;
  padding: 16px 32px;
  cursor: pointer;

  transition: ${props => props.theme.defaultTransition()};

  border: 4px solid #eef3f4;
  border-radius: 80px;

  &:after {
    content: "";
    width: 14px;
    height: 14px;
    background: url(${InputCaretNext}) no-repeat center center;
    display: ${props => (props.next ? "inline-block" : "none")};
    vertical-align: middle;
    margin-left: ${props => (props.next ? "5px" : "0")};

    position: relative;
    top: -1px;
  }

  &:before {
    content: "";
    width: 14px;
    height: 14px;
    background: url(${InputCaretBack}) no-repeat center center;
    display: ${props => (props.back ? "inline-block" : "none")};
    vertical-align: middle;
    margin-right: ${props => (props.back ? "5px" : "0")};
    position: relative;
    top: -1px;
  }

  &:hover {
    background-color: #eef3f4;
    border-color: #eef3f4;
  }

  span {
    background: ${props =>
      props.secondary === "A"
        ? "linear-gradient(90deg, #0F7F90 -8.75%, #00B08A 113.12%)"
        : "linear-gradient(128.61deg, #F38B97 20.01%, #F4904D 75.82%)"};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 991px) {
    width: 100%;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
  }
`

export const SupTitle = styled.span`
  color: ${props => (props.color ? props.color : props.theme.neutralGreen)};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  &:after {
    content: "";
    width: 39px;
    height: 1px;
    background: ${props => props.theme.orangeGradient};
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
`

export const InputContainer = styled.div`
  position: relative;
`

export const RegularInput = styled.input`
  width: 100%;
  background: #ffffff;
  opacity: 0.9;
  box-shadow: 1px 4px 20px rgba(243, 139, 151, 0.05);
  border-radius: 2px;
  border: 0;

  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;

  height: 40px;
  padding-left: 20px;
  color: ${props => props.theme.neutralDark};
  position: relative;

  &:focus {
    outline: none;
    box-shadow: 1px 10px 30px rgba(243, 139, 151, 0.27);
  }

  ::placeholder {
    color: ${props => props.theme.neutralDark};
    opacity: 0.5;
  }

  :-ms-input-placeholder {
    color: ${props => props.theme.neutralDark};
    opacity: 0.5;
  }

  ::-ms-input-placeholder {
    color: ${props => props.theme.neutralDark};
    opacity: 0.5;
  }
  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
export const InputMessage = styled.div`
  background: linear-gradient(-51.56deg, #f38b97 0%, #f4904d 80%);
  box-shadow: 0px 4px 9px rgba(0, 43, 52, 0.12);
  border-radius: 8px;
  color: white;
  border-bottom-right-radius: ${props => (props.helpCaret ? "8px" : "0")};
  border-top-right-radius: ${props => (props.helpCaret ? "0" : "8xp")};
  width: max-content;
  padding: 6.5px 12px;
  position: absolute;
  right: 35px;
  top: ${props => (props.helpCaret ? "30px" : "-25px")};

  max-width: ${props => (props.helpCaret ? "290px" : "unset")};

  opacity: ${props => (props.msgVisible === props.index ? 1 : 0)};
  visibility: ${props => (props.msgVisible === props.index ? 1 : 0)};
  transition: ${props => props.theme.defaultTransition()};

  span {
    transform: initial;
    font-weight: 500;
    color: white !important;
    opacity: 1 !important;
  }
`

export const InputCaret = styled.div`
  width: 60px;
  height: 40px;
  right: 0;
  position: absolute;
  top: 0;
  cursor: pointer;
  display: ${props => (props.helpCaret ? "none" : "block")};

  &:after {
    content: "";
    width: 8px;
    height: 8px;
    border: 1px solid #b0c9cb;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    border-color: ${props => (props.activeRequired ? "#b0c9cb" : "white")};
  }

  &:hover {
    &:after {
      border-color: ${props => (props.activeRequired ? "#F4904D" : "white")};
    }
  }
`

export const HelpImg = styled.div`
  background: url(${QuestionMark}) no-repeat center center;
  width: 60px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;

  display: ${props => (props.helpCaret ? "block" : "none")};
  cursor: pointer;
`
