import React, { useState } from "react"
import { useStaticQuery } from "gatsby"
import moment from "moment"
import { Nav, MenuItem, MenuDropdown, NavItem } from "./style"
import { Link } from "gatsby"
import EventDropdown from "./EventDropdown"
import SVGIcon from "../SVGIcon"
import onClickOutside from "react-onclickoutside"

const Menu = ({ items, active }) => {
  const {
    allWordpressWpUpcomingEvents: { edges },
  } = useStaticQuery(graphql`
    query MenuQuery {
      allWordpressWpUpcomingEvents {
        edges {
          node {
            acf {
              event_information {
                date
                event_description
                event_title
                events_agenda
                is_registration_open
                location
                show_event_in_menu
              }
            }
          }
        }
      }
    }
  `)

  const sortedDates = edges
  .slice()
  .sort(
    (a, b) =>
      parseInt(moment(b.node.acf.event_information.date).format("x")) -
      parseInt(moment(a.node.acf.event_information.date).format("x"))
  )
  const lastEdge = sortedDates[0]

  const [isOpen, setIsOpen] = useState(false)

  Menu.handleClickOutside = () => setIsOpen(false)

  return (
    <Nav active={active === null ? 0 : active}>
      {items.map((item, i) =>
        item.submenu ? (
          <MenuItem key={item.label}>
            <NavItem
              onMouseOver={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
              onFocus={() => null}
              className={isOpen ? "open" : "hide"}
            >
              <span className="with-more">{item.label}</span>
              <SVGIcon name="arrow" width={9} />
            </NavItem>
            {item.label === "Conference" ? (
              <EventDropdown isOpen={isOpen} info={lastEdge.node.acf.event_information}/>
            ) : (
              <MenuDropdown>
                {item.submenu.map(subitem => (
                  <Link to={subitem.path} key={subitem.label}>
                    {subitem.label}
                  </Link>
                ))}
              </MenuDropdown>
            )}
          </MenuItem>
        ) : (
          <MenuItem key={i} active={active === i}>
            <Link to={item.path}>{item.label}</Link>
          </MenuItem>
        )
      )}
    </Nav>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => Menu.handleClickOutside,
}

/* Menu.EventDropdown = EventDropdown; */
export default onClickOutside(Menu, clickOutsideConfig)
