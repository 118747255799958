import styled from "styled-components"

import { focusAnimation } from "../../../utils/KeyFrames"

import {
  MainH2,
  MainParagraph,
  RegularInput,
  InputMessage,
  InputContainer,
  InputCaret,
  HelpImg,
  SupTitle,
  MainTextLink,
  SecondaryBtn,
  PrimaryBtn,
  TextLinkWrap,
} from "../../../utils/GlobalComponents"
import { LG, XL } from "../../../utils/constants"

export const Wrapper = styled.div`
  background-color: ${props => props.theme.neutralSilver};
  border-radius: 8px;

  @media (max-width: ${XL}) {
    /* padding: 0 40px; */
  }

  @media (max-width: ${LG}) {
    /*   margin: 30px 0 -30px; */
/*     padding: ${props => (props.register ? "0" : "0")}; */
padding:0;

  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 80px 100px 0;

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
    margin: 80px 30px 0;
  }
`

export const TextContainer = styled.div`
  @media (min-width: ${LG}) {
    max-width: 330px;
  }
`

export const Title = styled(MainH2)`
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 40px;
`

export const Text = styled(MainParagraph)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.01em;
  color: ${props => props.theme.neutralDark};

  @media (min-width: ${LG}) {
    max-width: 294px;
  }
`

export const PersonalForm = styled.div`
/*   display: ${props => (props.visible === props.index ? "block" : "none")}; */
`

export const InputWrap = styled(InputContainer)`
  input {
    border: 0;
    position: relative;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`

export const Caret = styled(InputCaret)``

export const FormImput = styled(RegularInput)`
  margin-bottom: 28px;
  background-color: ${props => (props.activeFill ? "red" : "")};

  border: ${props =>
    props.errorInputInput === true ? "2px solid green" : "2px solid red"};
  color: ${props =>
    props.errorInputInput || props.errorInputMail ? "#003541" : "#FF5454"};
  caret-color: ${props => props.theme.neutralDark};

  &:focus {
    background: linear-gradient(175.34deg, #f38b97 20.01%, #f4904d 75.82%);
    background-position: bottom 0 left 0, bottom 5px left 0;
    background-repeat: no-repeat;
    color: #003541;
    animation: ${focusAnimation} 0.6s ease-in 0s forwards;
  }
`

export const FocusEffect = styled.div``

export const ValidationMark = styled.div`
  width: 100%;
  position: absolute;
  top: unset;
  right: 0;
  left: 0;
  bottom: ${props => (props.bottomFix ? props.bottomFix : "28px")};

  background-color: ${props => (props.borderColor ? "#09DCAE" : "#FF5454")};
  height: 2px;
  margin: 0 auto;
  border-bottom-left-radius: 170px;
  border-bottom-right-radius: 170px;
`

export const Message = styled(InputMessage)``

export const ImageHelp = styled(HelpImg)``

export const CustomGrid = styled.div`
  display: grid;
  grid-template-rows: 70px 70px 70px;

  div {
    &:last-child {
      grid-row: 2;
    }
  }
`

export const RegisterContent = styled.div`
  text-align: center;

  h2 {
    max-width: unset;
    margin-top: 20px;
    line-height: 50px !important;
    margin-bottom: 30px;
  }

  p {
    margin: 0 auto;
    padding-bottom: 20px;
    @media (min-width: ${LG}) {
      max-width: 440px;
    }
  }

  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    padding-bottom: 20px;
    letter-spacing: 0.01em;
    color: ${props => props.theme.navy};

    border-bottom: 1px solid rgba(176, 201, 203, 0.22);
  }

  @media (max-width: ${LG}) {
    text-align: left;

    > div {
      padding: 30px;
      &:first-child {
        padding: 30px;
      }
      &:last-child {
        background-color: white;
      }
    }
  }
`

export const CheckedInfo = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 30px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: ${props => props.theme.neutralDark};
    letter-spacing: 0.01em;

    @media (min-width: ${LG}) {
      max-width: 470px;
    }
  }
`

export const ConferenceReviewContainter = styled.div`
  margin-top: 80px;
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: ${props => props.theme.navy};
    border-bottom: 1px solid rgba(176, 201, 203, 0.22);
    padding-bottom: 10px;
    max-width: max-content;
    margin-bottom: 30px;
  }
  > div {
    display: flex;
    margin-bottom: 10px;

    img {
      width: 20px;
      height: 20px;
    }
    span {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: ${props => props.theme.neutralDark};
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      margin-left: 10px;
    }
  }
`

export const Toptitle = styled(SupTitle)`
  margin-top: 20px;
  margin-bottom: 30px;
  display: inline-block;
  &:after {
    display: none;
  }

  @media (max-width: ${LG}) {
    margin-bottom: 10px;
  }
`

export const EventDetails = styled.div`
  background-color: white;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  justify-items: center;
  padding: 35px 0;
  @media (min-width: ${LG}) {
    max-width: 576px;
  }

  > div {
    display: flex;
    margin-bottom: 5px;
    margin-top: 5px;

    img {
      width: 20px;
      height: 20px;
    }
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${props => props.theme.neutralDark};
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      margin-left: 10px;
    }
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
    justify-items: flex-start;

    > div {
      span {
        font-weight: 400;
      }
    }
  }
`
export const TextLinksContainer = styled.div`
  margin-top: 50px;
  padding-bottom: 60px;
  margin-left: 45px;
  margin-bottom: 50px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  a {

    margin: 0;

  }
  @media (max-width: ${LG}) {
    margin-left: 0;
    margin-top: 30px;
    padding-bottom: 0;
    margin-bottom: 50px;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;

    > a {
      margin-right: 0;
      padding-top: 0;
      padding-bottom: 40px;
      display: block;
    }
  }
`

export const LinkWrap = styled(TextLinkWrap)`
  margin-right: 40px;

  @media (max-width: ${LG}) {
    margin-right: 0;
    margin-bottom: 30px;
  }
`

export const TextLink = styled(MainTextLink)`
  margin-right: 45px;
`

export const StepsButton = styled(SecondaryBtn)`
  margin-left: 30px;

  display: ${props =>
    props.index <= 0 || props.nextIndex === 1 ? "none" : "inline-block"};

  span {
    margin-right: ${props => (props.next ? "0" : "10px")};
    margin-left: ${props => (props.next ? "10px" : "0")};
  }

  @media (max-width: ${LG}) {
    display: ${props =>
      props.index <= 0 || props.nextIndex === 1 ? "none" : "inline-block"};
    max-width: ${props => (props.back ? "90px" : "auto")};
    padding: ${props => (props.back ? "0" : "auto")};
    border: ${props => (props.back ? "0" : "auto")};

    &:hover {
      background-color: transparent;
    }
  }
`
export const SubmitButton = styled(PrimaryBtn)`
  display: ${props => (props.submit ? "block" : "none")};
  
  margin-left: 30px;

  @media (max-width: ${LG}) {
    display: ${props => (props.submit ? "block!important" : "none")};
  }

  span {
    color: white;
    opacity: 1;
  }
`

export const ButtonContainer = styled.div`
  padding-top: 50px;
  display: ${props => (props.hide ? "none" : "flex")};
  justify-content: flex-start;
  padding-right: 80px;
  padding-bottom: 40px;
  flex-direction: row-reverse;
  background-color: ${props => props.theme.neutralSilver};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  margin-bottom: 50px;

  @media (max-width: ${LG}) {
    padding: 20px 40px 60px;
    margin: 0;
  }
`

export const MobileTextContainer = styled.div`
  background-color: white;
  padding: 60px 30px 30px;
`

export const MobileTitle = styled(MainH2)`
  margin-bottom: 20px;
  line-height: 36px;

  @media (max-width: ${LG}) {
    max-width: unset;
    line-height: 40px;
  }
`

export const MobileText = styled(MainParagraph)`
  font-weight: 500;
`
export const RequiredGeneralMessage = styled.div`
  font-size: 14px;
  line-height: 24px;
  padding-left: 5px;
  font-weight: 500;
  color: #ff5454;
  margin-top: 15px;
`
