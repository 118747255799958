import styled from "styled-components"

import {
  SecondaryBtn,
  PrimaryBtn
} from "../../utils/GlobalComponents"
import { LG, XL } from "../../utils/constants"

export const Wrapper = styled.div`
  .rodal {
    overflow-y: scroll;
    background: #faf7f5bf;
    padding: 0;
  }

  .rodal-dialog {
    background-color: ${props => props.theme.neutralSilver};
    max-height: none;
    max-width: 1184px;
    padding: 0 0 30px;
    box-shadow: 1px 28px 60px rgba(0, 43, 52, 0.08);
    margin: auto;
    border-radius: 8px;
    z-index: 200;
    padding: 0;
    max-height: 820px !important;
    height: calc(100% - 140px) !important;

    @media (max-width: ${XL}) {
      max-width: 850px;
    }

    @media (max-width: ${LG}) {
      height: 100% !important;
      margin: 0;
      padding: 0;
      border-radius: 0;
    }
  }

  .rodal-mask {
    opacity: 0.8;
    background: transparent;
    outline: 0;
  }

  .rodal-dialog::-webkit-scrollbar {
    width: 8px;
  }
  .rodal-dialog {
    scrollbar-width: thin;
    scrollbar-color: linear-gradient(0deg, #f38b97 20.01%, #f4904d 100%);
  }
  .rodal-dialog::-webkit-scrollbar-thumb {
    background: rgba(176, 201, 203, 0.22);
    border-radius: 20px;
  }

  .rodal-close {
    position: absolute;
    background-color: white;
    opacity: 1;
    border-radius: 50%;
    box-shadow: 0px 4px 9px rgba(0, 43, 52, 0.12);
    padding: 27px;
    transition: ${props => props.theme.defaultTransition()};
    top: -20px;
    right: -20px;

    &:after,
    &:before {
      width: 40%;

      background-color: ${props => props.theme.lightGreen};
      margin: 0 auto;
      opacity: 1;
      left: 30%;
    }
    @media (max-width: ${LG}) {
      top: 15px;
      right: 15px;
    }
  }
`

export const StepsButton = styled(SecondaryBtn)`
  margin-left: 30px;

  display: ${props =>
    props.index <= 0 || props.nextIndex === 2 ? "none" : "inline-block"};

  span {
    margin-right: ${props => (props.next ? "0" : "10px")};
    margin-left: ${props => (props.next ? "10px" : "0")};
  }

  @media (max-width: ${LG}) {
    display: ${props =>
    props.index <= 0 || props.nextIndex === 2 ? "none" : "inline-block"};
    max-width: ${props => (props.back ? "90px" : "auto")};
    padding: ${props => (props.back ? "0" : "auto")};
    border: ${props => (props.back ? "0" : "auto")};

    &:hover {
      background-color: transparent;
    }
  }
`
export const SubmitButton = styled(PrimaryBtn)`
  display: ${props => (props.submit ? "block" : "none")};
  margin-left: 30px;

  @media (max-width: ${LG}) {
    display: ${props => (props.submit ? "block!important" : "none")};
  }
`

export const ButtonContainer = styled.div`
  padding-top: 50px;
  display: ${props => (props.hide ? "none" : "flex")};
  justify-content: flex-start;
  padding-right: 80px;
  padding-bottom: 40px;
  flex-direction: row-reverse;
  background-color: ${props => props.theme.neutralSilver};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  @media (max-width: ${LG}) {
    padding: 20px 40px;
  }
`
