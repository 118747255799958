import { keyframes } from "styled-components"

export const textLinkAnimation = keyframes`
  0% {
    left: 0;
  }
  30% {
    left: 100%;
  }
  60% {
    left: unset;
    right:100%;
  }
  100% {
    left: unset;
    right:0%;
  }
`

export const transformBlob = keyframes`

0%,
100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; } 
 14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; } 
 28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; } 
 42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; } 
 56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; } 
 70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; } 
 84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; } 


`

export const movementBlob = keyframes`
  0%,
100% { transform: none; }
 50% { transform: translateY(10%) rotateY(10deg); }
`

export const focusAnimation = keyframes`


0% {
  background-size: 0 2px;
  }
  100% {
    background-size: 100% 2px;
  }

`
