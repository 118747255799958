import React, { Fragment } from "react"
import {
  Wrapper,
  TabContainer,
  Tab,
  MobileTabsContainer,
  MobileTab,
  MobileTitleWrap,
  CaretBottom,
  CaretWrap,
  MobileTitle,
} from "./style"
import { useMediaQuery } from "react-responsive"

const StepBar = ({ steps, setSteps, state }) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })
  const isDesktop = useMediaQuery({
    query: "(min-device-width: 992px)",
  })

  const stepsInfo = [
    {
      title: "Enter personal information",
    },
    {
      title: "Review and submit",
    },
  ]

  return (
    <Fragment>
      {isDesktop && (
        <Wrapper hide={steps === 2}>
          <TabContainer>
            {stepsInfo.map((tab, i) => (
              <Tab
                onClick={() => {
                  if (
                    (steps === 0 || steps === 1) &&
                    i < 2 &&
                    state.fullname !== "" &&
                    state.email !== "" &&
                    state.position !== "" &&
                    state.hear !== ""
                  ) {
                    setSteps(i)
                  }
                }}
                index={i}
                key={i}
                tabActive={steps}
              >
                <span>{i + 1}.</span> {tab.title}
              </Tab>
            ))}
          </TabContainer>
        </Wrapper>
      )}
      {isTabletOrMobileDevice && (
        <Wrapper hide={steps === 2}>
          <MobileTabsContainer>
            {stepsInfo.map((tab, y) => (
              <div
                key={y}
                onClick={() => {
                  if (
                    (steps === 0 || steps === 1) &&
                    y < 2 &&
                    state.fullname !== "" &&
                    state.email !== "" &&
                    state.position !== "" &&
                    state.hear !== ""
                  ) {
                    setSteps(y)
                  }

                
                }}
              >
                <MobileTab index={y} key={y} tabActive={steps}>
                  {y + 1}
                </MobileTab>
                <CaretWrap>
                  <CaretBottom
                    index={y + 1}
                    key={y + 1}
                    tabActive={steps + 1}
                  />
                </CaretWrap>
              </div>
            ))}
          </MobileTabsContainer>
          <MobileTitleWrap>
            {stepsInfo.map((tab, x) => (
              <Fragment key={x}>
                <MobileTitle index={x + 1} key={x + 1} tabActive={steps + 1}>
                  <span>{x + 1}. </span> {tab.title}
                </MobileTitle>
              </Fragment>
            ))}
          </MobileTitleWrap>
        </Wrapper>
      )}
    </Fragment>
  )
}

export default StepBar
