import styled from "styled-components"

import OrangeCaret from "../../../images/orange-down-caret.svg"

export const Wrapper = styled.div`
  width: 100%;
  background: #ffffff;
  opacity: ${props => (props.open ? 1 : 0.9)};
  box-shadow: 1px 4px 20px rgba(243, 139, 151, 0.13);
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  height: 40px;
  z-index: 200;

  span {
    color: ${props => props.theme.neutralDark};
    padding-top: 10px;
    display: inline-block;
    padding-left: 18px;
    font-size:14px !important;
    font-weight:400;

    opacity: ${props => (props.initial !== true ? 0.5 : 1)};
  }
`

export const DropButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 55px;
  height: 40px;
  border-left: 1px solid rgba(176, 201, 203, 0.22);

  &:after {
    content: "";
    background: url(${OrangeCaret}) no-repeat center center;
    top: 0;
    width: 50px;
    height: 40px;
    display: block;

    transform: ${props => (props.open ? "rotate(180deg)" : "rotate(0)")};
  }
`

export const Menu = styled.div`
  position: relative;
  top: 20px;
  background: white;
  display: ${props => (props.open ? "block" : "none")};
  padding: 10px 0;
  background: #ffffff;
  box-shadow: 1px 10px 30px rgba(243, 139, 151, 0.27);
  border-radius: 2px;

  &:after {
    content: "";
    width: 100%;
    height: 4px;
    background: ${props => props.theme.orangeGradient};
    display: block;
    top: 10px;
    position: relative;
  }


`

export const Item = styled.input`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.neutralDark};
  opacity: 0.5;

  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
  padding-left: 18px;
  width: 100%;
  border: 0;
  cursor: pointer;
  outline: 0;

  opacity: ${props => (props.selectValue === props.index ? 1 : 0.5)};

  &:hover {
    background: rgba(176, 201, 203, 0.22);
    opacity: 1;
  }
`
