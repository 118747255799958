import React from "react"
import { Link } from "gatsby"
import { socialLinks, menuItems } from "../../utils/constants"
import {
  FooterWrapper,
  ContactWrapper,
  ContactInfo,
  SocialContainer,
  FooterMiddle,
  FirstColumn,
  SecondColumn,
  MenuContainer,
  FooterBottom,
  TickLogo,
} from "./style"
import { MainH2, MainH4 } from "../../utils/GlobalComponents"
import SVGIcon from "../common/SVGIcon"
import LimeandtickUrl from "../../images/limeandtick.svg"
import Logo from "../common/Logo"
import Blob from "../common/Blob/Blob"
import { useMediaQuery } from "react-responsive"
import TickLogoPic from "../../images/ticks-logo.png"

const Footer = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })

  return (
    <FooterWrapper>
      <Blob
        toTop={isTabletOrMobileDevice ? "65%" : "50%"}
        toRight={isTabletOrMobileDevice ? "50%" : "14%"}
        customWidth={"11vw"}
        customHeight={"12vw"}
      />
      {!isTabletOrMobileDevice && (
        <Blob
          toTop={isTabletOrMobileDevice ? "54%" : "60%"}
          toLeft={isTabletOrMobileDevice ? "300px" : "-250px"}
          customWidth={isTabletOrMobileDevice ? "60vw" : "24vw"}
          customHeight={isTabletOrMobileDevice ? "60vw" : "26vw"}
        />
      )}

      <ContactWrapper>
        <MainH2 data-aos="fade-up" data-aos-duration="1000">
          Contact us
        </MainH2>
        <MainH4 data-aos="fade-up" data-aos-duration="1000">
          Icahn School of Medicine at Mount Sinai <span>|</span> LymeMIND
        </MainH4>
        <ContactInfo>
          <div data-aos="fade-up" data-aos-duration="1000" style={{textAlign: 'left'}}>
            <a
              href="https://www.google.com/maps/place/70+Lexington+Ave,+Jersey+City,+NJ+07304,+USA/@40.718589,-74.0821367,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25126332d2c4b:0xa9a02ce29953a0df!8m2!3d40.718589!4d-74.079948"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SVGIcon name="location" width={12} />
              One Gustave L. Levy Place, Icahn School of Medicine at Mount Sinai, New York, NY 10029
            </a>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000">
            <a href="tel:2122411153" rel="noopener noreferrer" style={{whiteSpace: 'nowrap'}}>
              <SVGIcon name="phone" width={14} />
              212-241-1153
            </a>
            <a
              href="mailto:avi.maayan@mssm.edu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SVGIcon name="email" width={15} />
              avi.maayan@mssm.edu
            </a>
          </div>
        </ContactInfo>
        <MainH4 data-aos="fade-up" data-aos-duration="1000">
          <strong>Stay connected with LymeMIND</strong>
        </MainH4>
        <SocialContainer data-aos="fade-up" data-aos-duration="1000">
          {socialLinks.map(item => (
            <a
              href={item.path}
              target="_blank"
              rel="noopener noreferrer"
              className={item.icon}
              key={item.label}
            >
              <SVGIcon name={item.icon} width={16} /> {item.label}
            </a>
          ))}
        </SocialContainer>
      </ContactWrapper>
      <FooterMiddle>
        <FirstColumn data-aos="fade-up" data-aos-duration="1000">
          <img src={LimeandtickUrl} alt="Lime and Tick" />
          <p>
            LymeMIND is made possible by generous support from the Cohen Lyme
            &amp; Tickborne Disease Initiative. Launched in 2015, the Initiative
            seeks to gather the brightest minds within the research and medical
            communities to better understand and ultimately stop the rapid
            spread of tickborne illnesses. It has committed over $60 million to
            more than 25 research projects across the nation.
          </p>
        </FirstColumn>
        <SecondColumn
          border
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <MenuContainer>
            {isTabletOrMobileDevice && (
              <TickLogo src={TickLogoPic} alt="Ticks Suck" />
            )}
            {menuItems.map(
              item =>
                item.label !== "Events" && (
                  <li key={item.label}>
                    <Link to={item.path}>{item.label}</Link>
                  </li>
                )
            )}
          </MenuContainer>
        </SecondColumn>
        {!isTabletOrMobileDevice && (
          <TickLogo src={TickLogoPic} alt="Ticks Suck" />
        )}
      </FooterMiddle>
      <FooterBottom data-aos="fade-up" data-aos-duration="1000">
        <FirstColumn>
          <div className="copyright">© 2020 LymeMIND. All rights reserved.</div>
        </FirstColumn>
        <SecondColumn>
          <Logo width={150} />
        </SecondColumn>
      </FooterBottom>
    </FooterWrapper>
  )
}

export default Footer
