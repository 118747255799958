import React, { useState, useEffect, Fragment } from "react"
import { useStaticQuery } from "gatsby"
import { useMediaQuery } from "react-responsive"
import axios from "axios"

import {
  Wrapper,
  Title,
  Text,
  Grid,
  TextContainer,
  PersonalForm,
  FormImput,
  InputWrap,
  Caret,
  Message,
  ImageHelp,
  CustomGrid,
  CheckedInfo,
  ConferenceReviewContainter,
  RegisterContent,
  Toptitle,
  EventDetails,
  TextLink,
  TextLinksContainer,
  StepsButton,
  ButtonContainer,
  SubmitButton,
  MobileTextContainer,
  MobileTitle,
  MobileText,
  RequiredGeneralMessage,
  LinkWrap,
  ValidationMark,
} from "./style"

import StepBar from "../StepBar/StepBar"
import SelectCustom from "../../common/SelectCustom/SelectCustom"
import InputUnderline from "../../common/InputUnderline/InputUnderline"
import RegisterCheck from "../../../images/green-checked.svg"

import CalendarIcon from "../../../images/lyme-local-images/Calendar-3.png"
import PinIcon from "../../../images/lyme-local-images/Pin-2.png"

const PersonalInfo = () => {
  const {
    allWordpressWpRegisterForm: { edges },
  } = useStaticQuery(graphql`
    query RegisterQuery {
      allWordpressWpRegisterForm {
        edges {
          node {
            acf {
              register_form_add_to_calendar
              register_form_download_ics {
                url {
                  source_url
                }
              }
              register_form_event_date
              register_form_event_place
              register_form_event_title
              register_form_success
              register_form_success_text_2
              register_form_text
              register_form_title
            }
          }
        }
      }
    }
  `)

  const [msgActive, setMsgActive] = useState(0)
  let [steps, setSteps] = useState(0)
  const [activeFill, setActiveFill] = useState(false)

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })
  const isDesktop = useMediaQuery({
    query: "(min-device-width: 992px)",
  })

  const [formData] = useState({
    PersonalInfo: [
      {
        fullName: "",
        type: "fullname",
        placeholder: "Full Name",
        activeRequired: true,
        help: false,
        message: "",
        name: "fullname",
      },
      {
        Email: "",
        type: "email",
        placeholder: "E-mail",
        activeRequired: true,
        help: false,
        message: "",
        name: "email",
      },
      {
        PhoneNumber: "",
        type: "phonenumber",
        placeholder: "Phone Number",
        activeRequired: false,
        help: false,
        message: "",
        name: "phonenumber",
      },
      {
        PositionOrTitle: "",
        type: "position",
        placeholder: "Position or title",
        activeRequired: true,
        help: false,
        message: "",
        name: "position",
      },
      {
        PersonalAffiliation: "",
        type: "affiliation",
        placeholder: "Professional affiliation",
        activeRequired: false,
        help: true,
        message: "If Applicable, Will appear on conference badge",
        name: "affiliation",
      },
      {
        PositionOrTitle: "",
        type: "hear",
        placeholder: "How did you hear about this event?",
        activeRequired: true,
        help: false,
        message: "",
        name: "hear",
      },
    ],
    AdditionalInfo: [
      {
        PositionOrTitle: "",
        type: "hear",
        placeholder: "How did you hear about this event?",
        activeRequired: true,
        help: false,
        message: "",
        name: "hear",
      },
      {
        Dietary: "",
        type: "dietary",
        placeholder: "Describe any dietary restrictions",
        activeRequired: true,
        help: false,
        message: "",
        name: "dietary",
      },
    ],
  })

  const SelectData = [
    "Role in your community",
    "Researcher",
    "Consortium member",
    "Sponsor",
    "Lyme disease patient",
    "Caregiver for a Lyme disease patient",
    "Other",
  ]

  const reviewData = [
    {
      field: "Full Name ",
      value: "Kathleen S. Stecher",
      fullRow: true,
    },
    {
      field: "E-mail ",
      value: "Kathleen@gmail.com ",
      fullRow: true,
    },
    {
      field: "Phone Number ",
      value: "212-731-7071",
      fullRow: true,
    },
    {
      field: "Position or Title",
      value: "Administrative Manager",
      fullRow: true,
    },
    {
      field: " Professional Affiliation",
      value: "LymeMIND Team",
      fullRow: true,
    },
    {
      field: "How did you hear about this event?",
      value: " Through a colleague ",
      fullRow: true,
    },
    {
      field: "Role in our community",
      value: "I help organize the LymeMIND conferences every year",
      fullRow: true,
    },
  ]

  const [state, setState] = useState({
    fullname: "",
    email: "",
    phonenumber: "",
    position: "",
    affiliation: "",
    hear: "",
    role: "",
  })

  const [selectedItem, setSelectedItem] = useState(0)
  const [selectedChecked, setSelectedChecked] = useState(false)
  const [validateEmail, setValidateEmail] = useState("empty")

  const handleInputChange = (event, index, formType) => {
    const target = event.target
    const value = target.value
    const name = target.name

    setState(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })

    if (name === "email") {
      handleValidMail(state.email)
    }

    if (index !== undefined && formType === "customSelect") {
      setSelectedItem(index)
    }

    if (formType === "checked") {
      if (!selectedChecked) {
        setSelectedChecked(true)
      } else {
        setSelectedChecked(false)
      }
    }
  }

  const handleValidMail = email => {
    let re = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,16}/g)

    if (state === "") {
      setValidateEmail(false)
    }

    if (re.test(email)) {
      setValidateEmail(true)
    } else {
      setValidateEmail(false)
    }
  }

  useEffect(() => {
    setValidateEmail("empty")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitForm = async () => {
    try {
      var bodyFormData = new FormData()
      bodyFormData.set("fullname", state.fullname)
      bodyFormData.set("email", state.email.toLowerCase())
      bodyFormData.set("phonenumber", state.phonenumber)
      bodyFormData.set("position", state.position)
      bodyFormData.set("affiliation", state.affiliation)
      bodyFormData.set("hear", state.hear)
      bodyFormData.set("role", state.role)
      await axios({
        method: "post",
        url:
          "https://cms.lymemind.org/wp-json/contact-form-7/v1/contact-forms/1866/feedback",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      setSteps(2)
    } catch (error) {}
  }
  return (
    <Fragment>
      {edges.map(({ node }, s) => (
        <Wrapper key={s}>
          {isTabletOrMobileDevice && (
            <Fragment>
              {steps === 0 ? (
                <MobileTextContainer>
                  <MobileTitle>{node.acf.register_form_title}</MobileTitle>
                  <MobileText
                    dangerouslySetInnerHTML={{
                      __html: node.acf.register_form_text,
                    }}
                  />
                </MobileTextContainer>
              ) : null}
              {steps === 1 && (
                <MobileTextContainer>
                  <MobileTitle>Review your information </MobileTitle>
                  <MobileText>
                    Check your information and make sure everything is correct.
                    We will be sending your event ticket and confirmation
                    details after you confirm.
                  </MobileText>
                </MobileTextContainer>
              )}
            </Fragment>
          )}

          <StepBar steps={steps} setSteps={setSteps} state={state} />
        </Wrapper>
      ))}

      <Wrapper>
        <Grid>
          {isDesktop && (
            <Fragment>
              {steps === 0 ? (
                <TextContainer>
                  <Fragment>
                    {edges.map(({ node }, l) => (
                      <Fragment key={l}>
                        <Title>{node.acf.register_form_title}</Title>
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: node.acf.register_form_text,
                          }}
                        />
                      </Fragment>
                    ))}
                  </Fragment>
                </TextContainer>
              ) : null}
              {steps === 1 ? (
                <TextContainer>
                  {edges.map(({ node }, v) => (
                    <Fragment key={v}>
                      <Title>{node.acf.register_form_title}</Title>
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: node.acf.register_form_text,
                        }}
                      />
                    </Fragment>
                  ))}
                  {edges.map(({ node }, y) => (
                    <ConferenceReviewContainter key={y}>
                      <h4>{node.acf.register_form_event_title}</h4>
                      <div>
                        <img src={CalendarIcon} alt={"Calendar"} />
                        <span>{node.acf.register_form_event_date}</span>
                      </div>
                      <div>
                        <img src={PinIcon} alt={"Pin"} />
                        <span>{node.acf.register_form_event_place}</span>
                      </div>
                    </ConferenceReviewContainter>
                  ))}
                </TextContainer>
              ) : null}

              {steps === 4 && (
                <TextContainer>
                  <Title>Review your Information</Title>
                  <Text>
                    Please make sure your information is correct before
                    submission. We will be sending you an email with directions
                    to join the virtual event.
                  </Text>
                </TextContainer>
              )}
            </Fragment>
          )}

          {steps === 0 && (
            <PersonalForm index={0} visible={steps}>
              <Fragment>
                {formData["PersonalInfo"].map((item, i) => {
                  return (
                    <InputWrap key={i}>
                      {i === 1 ? (
                        <div className={`mailInput ${validateEmail}`}>
                          <FormImput
                            placeholder={"E-mail"}
                            required={item.activeRequired}
                            name={item.name}
                            value={state.name}
                            defaultValue={state["email"]}
                            onBlur={e => {
                              handleInputChange(e, i)
                            }}
                            onFocus={e => {
                              handleInputChange(e, i)
                            }}
                            onChange={e => {
                              handleInputChange(e, i)
                            }}
                            index={i}
                            errorInputMail={
                              item.name === "email" && validateEmail
                            }
                            autoComplete="off"
                          />

                          {state.email.length > 0 ? (
                            <ValidationMark
                              borderColor={validateEmail}
                            ></ValidationMark>
                          ) : null}
                        </div>
                      ) : (
                        <div className={`textInput`}>
                          <FormImput
                            placeholder={item.placeholder}
                            required={item.activeRequired}
                            name={item.name}
                            value={state.name}
                            onChange={e => {
                              handleInputChange(e, i)
                            }}
                            type={i === 2 ? "number" : "text"}
                            index={i}
                            defaultValue={state[item.type]}
                            autoComplete="off"
                            errorInputInput={state[item.type].length > 0}
                          />
                          {state[item.type].length > 0 ? (
                            <ValidationMark
                              borderColor={state[item.type].length > 0}
                            ></ValidationMark>
                          ) : null}
                        </div>
                      )}

                      {item.activeRequired && (
                        <Message index={i + 1} msgVisible={msgActive}>
                          <span>Required Field</span>
                        </Message>
                      )}

                      {/*   {item.help && (
                        <Message
                          index={i + 1}
                          msgVisible={msgActive}
                          helpCaret={item.help}
                        >
                          <span>{item.message}</span>
                        </Message>
                      )} */}

                      <Caret
                        activeRequired={item.activeRequired}
                        onFocus={() => setMsgActive(i + 1)}
                        onMouseOver={() => setMsgActive(i + 1)}
                        onMouseLeave={() => setMsgActive(0)}
                      ></Caret>

                      {/*   <ImageHelp
                        onFocus={() => setMsgActive(i + 1)}
                        onMouseOver={() => setMsgActive(i + 1)}
                        onMouseLeave={() => setMsgActive(0)}
                        helpCaret={item.help}
                      ></ImageHelp> */}
                    </InputWrap>
                  )
                })}

                <SelectCustom
                  SelectData={SelectData}
                  handleInputChange={handleInputChange}
                  name="role"
                  selectedItem={selectedItem}
                />

                {activeFill ? (
                  <RequiredGeneralMessage>
                    Check required fields marked
                  </RequiredGeneralMessage>
                ) : (
                  ""
                )}
              </Fragment>
            </PersonalForm>
          )}

          {steps === 1 && (
            <PersonalForm index={1} visible={steps}>
              <Fragment>
                <div>
                  {reviewData.map((item, z) => {
                    return (
                      <InputUnderline
                        key={z}
                        index={z}
                        reviewData={reviewData[z]}
                        stateData={[
                          {
                            value: state.fullname,
                          },
                          {
                            value: state.email,
                          },
                          {
                            value: state.phonenumber,
                          },
                          {
                            value: state.position,
                          },
                          {
                            value: state.affiliation,
                          },
                          { value: state.hear },
                          {
                            value: state.role,
                          },
                        ]}
                      />
                    )
                  })}
                </div>
              </Fragment>
            </PersonalForm>
          )}
        </Grid>
      </Wrapper>
      <ButtonContainer hide={steps === 2}>
        <SubmitButton
          primary={"A"}
          to=""
          as="button"
          submit={steps === 1}
          onClick={() => submitForm()}
        >
          <span>Submit</span>
        </SubmitButton>
        <StepsButton
          next={true}
          secondary={"A"}
          nextIndex={steps}
          to=""
          as="button"
          onClick={() => {
            if (
              steps < 1 &&
              state.fullname !== "" &&
              state.email !== "" &&
              validateEmail === true &&
              state.position !== "" &&
              state.hear !== ""
            ) {
              setSteps(steps + 1)
              setActiveFill(false)
            } else {
              setActiveFill(true)
            }
          }}
        >
          <span>Next</span>
        </StepsButton>
        <StepsButton
          back={true}
          index={steps}
          secondary={"A"}
          to=""
          as="button"
          onClick={() => {
            if (steps < 3 && steps > 0) {
              setSteps(steps - 1)
            }
          }}
        >
          <span>Back</span>
        </StepsButton>
      </ButtonContainer>
      <Wrapper register={true}>
        {steps === 2 ? (
          <PersonalForm index={2} visible={steps}>
            <RegisterContent>
              {edges.map(({ node }, u) => (
                <div key={u}>
                  <img src={RegisterCheck} alt="Checked" />
                  <Title>Thank you for registering </Title>
                  <div style={{textAlign: "left"}}>
                    <Text><b>Confirmation</b></Text>
                    <Text>You are confirmed to attend this year’s virtual LymeMIND Conference. Please note that timeframes listed on the agenda are approximate and may vary slightly once the event has begun. Thank you in advance for your participation – we look forward to a fantastic event!</Text>
                    <Text><b>To Join the Event</b></Text>
                    <Text>Please click this <a href="https://kvgo.com/point72/LymeMINDconference2021">link</a> and enter the required details and hit submit. You will then be directed to the conference. For those entering before noon on Saturday, you will be directed to a holding page until the event begins. No further action is required.</Text>
                    <Text><b>Questions for Panelists</b></Text>
                    <Text>Throughout the course of the conference, questions can be submitted to panelists using the section located on the left of your browser. We will do our best to answer as many questions as possible during the allotted time.</Text>
                    <Text><b>Support</b></Text>
                    <Text>Any issues with gaining access to the event can be directed to LymeMIND Conference (lymeMIND@steveandalex.org).</Text>
                    <Text>Warm wishes,</Text>
                    <Text>The LymeMIND Team</Text>
                  </div>
                </div>
              ))}

              <div>
                <Toptitle>Event details</Toptitle>
                <Fragment>
                  {edges.map(
                    ({ node }, r) =>
                      isTabletOrMobileDevice && (
                        <h5 key={r}>{node.acf.register_form_title}</h5>
                      )
                  )}
                </Fragment>

                <Toptitle></Toptitle>
                <EventDetails>
                  {edges.map(({ node }, c) => (
                    <Fragment key={c}>
                      <div>
                        <img src={CalendarIcon} alt="Calendar" />
                        <span>{node.acf.register_form_event_date}</span>
                      </div>
                      <div>
                        <img src={PinIcon} alt={"Pin"} />
                        <span>{node.acf.register_form_event_place}</span>
                      </div>
                    </Fragment>
                  ))}
                </EventDetails>
                {edges.map(({ node }, w) => (
                  <TextLinksContainer key={w}>
                    <LinkWrap>
                      <TextLink
                        as="a"
                        href={node.acf.register_form_add_to_calendar}
                        target="_blank"
                        to=""
                      >
                        ADD TO CALENDAR
                      </TextLink>
                    </LinkWrap>

                    <LinkWrap>
                      <TextLink
                        as="a"
                        href={
                          node.acf.register_form_download_ics.url.source_url
                        }
                        target="_blank"
                        to=""
                      >
                        DOWNLOAD ICS
                      </TextLink>
                    </LinkWrap>
                  </TextLinksContainer>
                ))}
              </div>
            </RegisterContent>
          </PersonalForm>
        ) : null}
      </Wrapper>
    </Fragment>
  )
}

export default PersonalInfo
