import styled from "styled-components"

import BlogBackground from "../../../images/blob-gradient.png"

import { transformBlob, movementBlob } from "../../../utils/KeyFrames"

export const Wrapper = styled.div`
  position: absolute;
  z-index: 3;
`

export const BlobItem = styled.div`
  display: block;
  width: ${props => (props.customWidth ? props.customWidth : "40vw")};
  height: ${props => (props.customHeight ? props.customHeight : "40vw")};
/*   background: url(${BlogBackground}) no-repeat center center; */
  background:${props =>
    props.bg
      ? `url(${props.bg}) no-repeat center center`
      : `url(${BlogBackground}) no-repeat center center`};
  background-size: ${props =>
    props.bg ? "cover !important" : "contain !important"};
  box-shadow: 1px solid black;
  background-size: ${props => (props.bg ? "cover" : "auto")};
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  perspective: 1000px;
  animation: ${transformBlob} 15s ease-in-out infinite both alternate,
    ${movementBlob} 20s ease-in-out infinite both;
    position:relative;

    opacity:${props => (props.noMobile ? "0!important" : "1")};

  
`

export const AnimatedDiv = styled.div`
  position: absolute;
  right: ${props => (props.toRight ? props.toRight : "100px")};
  top: ${props => (props.toTop ? props.toTop : "initial")};
  left: ${props => (props.toLeft ? props.toLeft : "initial")};
  transform: translate(-50%, -50%);
`
