export default {
  /* dark: (opacity = 1) => `rgba(45,45,45,${opacity})`, */
  defaultTransition: (type = "all", s = 0.3) =>
    `${type} cubic-bezier(0.15, 0.82, 1, 1) ${s}s`,
  white: "#ffffff",
  navy: "#005163",
  lightNavy: "rgba(0, 81, 99, 0.46)",
  veryLightNavy: "rgba(0, 53, 65, 0.15)",
  lightBlue: "#0F7F90",
  lightGreen: "#00B08A",
  orange: "#F4904D",
  pink: "#F38B97",
  beige: "#FAF7F5;",
  neutralDark: " #003541",
  neutralGreen: "#B0C9CB",
  neutralSilver: "#FAFAFA",
  orangeGradient: "linear-gradient(104.79deg, #F38B97 20.01%, #F4904D 75.82%)",
  lightOrangeGradient:
    " linear-gradient(135.77deg, rgba(243, 139, 151, 0.1) 20.01%, rgba(244, 144, 77, 0.1) 75.82%)",
  orangeGradientHover:
    "linear-gradient(104.82deg, #FF7999 10.42%, #FFBA52 88.01%)",
  greenGradient: "linear-gradient(90deg, #0F7F90 -8.75%, #00B08A 113.12%)",
  greenGradientHover: "linear-gradient(90deg, #199BAF -8.75%, #09DCAE 113.12%)",

  borderGreen: "4px solid rgba(0, 81, 99, 0.1);",
  borderOrange: "4px solid rgba(243, 139, 151, 0.24)",
  boxShadowGreen: "0px 4px 9px rgba(0, 176, 138, 0.22)",
  boxShadowOrange: "0px 4px 9px rgba(244, 144, 77, 0.31)",
  boxShadow: "1px 28px 60px rgba(0, 43, 52, 0.08)",
  borderColor: "rgba(176, 201, 203, 0.22)",
  fbColor: "#3479EA",
  fbBkg: "rgba(53, 122, 234, 0.05)",
  ldColor: "#0076B4",
  ldBkg: "rgba(0, 118, 180, 0.05)",
  twColor: "#09A1E8",
  twBkg: "rgba(9, 161, 232, 0.05)",
  ytColor: "#EB3323",
  ytBkg: "rgba(235, 51, 35, 0.05)",
}
