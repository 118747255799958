import React from "react"
import { useStaticQuery } from "gatsby"
import { Link } from "gatsby"

import { Wrapper } from "./style"

const SubMenu = ({ isOpen, info }) => {
  const {
    allWordpressWpPastEvents: { edges },
  } = useStaticQuery(graphql`
    query PastEventsMenuMobile {
      allWordpressWpPastEvents(filter: {wordpress_id: {ne: 1627}}) {
        edges {
          node {
            title
          }
        }
      }
    }
  `)

  return (
    <Wrapper isOpen={isOpen}>
      {info.show_event_in_menu && (
        <div>
          <span>Upcoming event</span>
          <ul>
            <li>
              <Link to="/upcoming-event">2021 Event Details</Link>
            </li>
            {info.events_agenda && (
              <li>
                <Link to="/agenda">View Agenda</Link>
              </li>
            )}
          </ul>
        </div>
      )}

      <div>
        <span>Past events</span>
        <ul>
          {edges &&
            edges.map((confLink, i) => (
              <li key={i}>
          
                <Link to={`/past-event-${confLink.node.title}`}>
                  {confLink.node.title} Conference
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </Wrapper>
  )
}

export default SubMenu
