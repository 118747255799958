import styled from "styled-components"

export const Wrapper = styled.div`
  position: relative;
  z-index: 4;
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  height: ${props => (props.isOpen ? "350px" : "0")};
  opacity: ${props => (props.isOpen ? "1" : "0")};
  transition: ${props => props.theme.defaultTransition()};

  background-color: #00516321;
  padding-left: 40px;
  margin-left: -40px;
  /*   margin-top: 15px; */

  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    &:last-child {
      overflow: scroll;
    }
  }

  ul {
    visibility: ${props => (props.isOpen ? "visible" : "hidden")};
    opacity: ${props => (props.isOpen ? "1" : "0")};
    transition: ${props => props.theme.defaultTransition()};

    li {
      margin-bottom: 20px;
    }

    a {
      font-weight: normal;
      visibility: ${props => (props.isOpen ? "visible" : "hidden")};
      opacity: ${props => (props.isOpen ? "1" : "0")};
    }
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff85;
    display: inline-block;
    margin-bottom: 10px;

    visibility: ${props => (props.isOpen ? "visible" : "hidden")};
    opacity: ${props => (props.isOpen ? "1" : "0")};
    transition: ${props => props.theme.defaultTransition()};
  }
`
