import styled from "styled-components"

import { LG, BREAKPOINT, ONLY_HD } from "../../utils/constants"

export const FooterWrapper = styled.footer`
  text-align: center;
  position: relative;
  overflow: hidden;
  h2 {
    margin: 0 auto 27px;
  }
  h4 {
    span {
      color: ${props => props.theme.pink};
      margin: 0 10px;
    }

    @media (max-width: ${LG}) {
      max-width: 240px;
      margin: 0 auto;
      font-size: 22px;
      line-height: 31px;

      span {
        display: none;
      }
    }
  }
`

export const ContactWrapper = styled.div`
  padding: 100px 0;

  h4 {
    @media (max-width: ${LG}) {
      max-width: unset;
    }

    @media (max-width: ${BREAKPOINT}) {
      /*      max-width:204px; */
      max-width: unset;
      padding: 0 30px;
    }
  }
`

export const ContactInfo = styled.div`
  max-width: 1088px;
  height: 112px;
  background: ${props => props.theme.neutralSilver};
  color: ${props => props.theme.navy};
  border-radius: 8px;
  padding: 0 48px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin: 80px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;

    @media (max-width: ${LG}) {
      flex-direction: column;
      text-align: left;
    }
    a {
      &:first-child {
        margin-right: 65px;
      }
    }
  }
  a {
    color: ${props => props.theme.navy};
    display: flex;
    align-items: center;

    @media (max-width: ${LG}) {
      display: grid;
      grid-template-columns: 30px 1fr;
      margin-bottom: 5px;
    }

    svg {
      fill: url(#orangeGradient);
      margin-right: 18px;

      @media (max-width: ${LG}) {
        height: auto;
        align-self: flex-start;
        margin-top: 5px;
      }
    }
  }

  @media (max-width: ${LG}) {
    flex-direction: column;
    align-items: flex-start;
    margin: 80px 40px;
    padding: 30px 0;
    height: auto;
    padding-left: 30px;

    > div {
      margin-bottom: 10px;
    }
  }
`

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 23px auto 0;
  a {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 150px;
    margin: 0 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    svg {
      margin-right: 16px;
    }
    &.facebook {
      color: ${props => props.theme.fbColor};
      background: ${props => props.theme.fbBkg};
      svg {
        fill: ${props => props.theme.fbColor};
      }
      &:hover {
        background-color: rgba(53, 122, 234, 0.1);
      }
    }
    &.linkedin {
      color: ${props => props.theme.ldColor};
      background: ${props => props.theme.ldBkg};
      svg {
        fill: ${props => props.theme.ldColor};
      }
      &:hover {
        background-color: rgba(0, 118, 180, 0.1);
      }
    }
    &.twitter {
      color: ${props => props.theme.twColor};
      background: ${props => props.theme.twBkg};
      svg {
        fill: ${props => props.theme.twColor};
      }
      &:hover {
        background-color: rgba(9, 161, 232, 0.1);
      }
    }
    &.youtube {
      color: ${props => props.theme.ytColor};
      background: ${props => props.theme.ytBkg};
      svg {
        fill: ${props => props.theme.ytColor};
      }
      &:hover {
        background-color: rgba(235, 51, 35, 0.1);
      }
    }
  }

  @media (max-width: ${LG}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin: 0 20px;
    padding-top: 40px;
  }
`

export const FooterMiddle = styled.div`
  background: ${props => props.theme.neutralSilver};
  display: flex;
  justify-content: center;
  p {
    color: ${props => props.theme.lightNavy};
    margin-left: 50px;
    text-align: left;
    max-width: 467px;

    @media (max-width: ${LG}) {
      width: 100%;
      max-width: unset;
      margin-left: 0;
      margin-top: 30px;
      padding-bottom: 20px;
    }
  }
  & > div {
    height: 277px;

    @media (max-width: ${LG}) {
      display: block;
      width: 100%;
      text-align: left;
      height: auto;
    }
  }

  @media (max-width: ${LG}) {
    flex-direction: column-reverse;
  }
`

export const FirstColumn = styled.div`
  display: flex;
  align-items: center;
  padding: 0 88px;
  width: 66%;

  p {
    @media (max-width: ${LG}) {
      font-weight: 500;
    }
    margin-bottom: 0;
  }

  @media (max-width: ${LG}) {
    padding: 0 30px;
  }
`
export const SecondColumn = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  width: 25%;
  padding-left: 88px;
  border-left: 1px solid transparent;
  border-color: ${props =>
    props.border ? props.theme.borderColor : "transparent"};

  @media (max-width: ${LG}) {
    padding-left: 30px;
    width: auto;
  }
`

export const MenuContainer = styled.ul`
  li {
    display: block;
    text-align: left;
    margin-bottom: 4px;

    @media (max-width: ${LG}) {
      margin-bottom: 10px;
    }
    a {
      color: ${props => props.theme.navy};
      font-weight: 500;
      opacity: 0.7;

      @media (max-width: ${LG}) {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        letter-spacing: 0.01em;
      }

      @media (max-width: ${BREAKPOINT}) {
        opacity: 1;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
  @media (max-width: ${LG}) {
    padding-top: 40px;
    padding-bottom: 20px;
  }
`

export const FooterContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

export const FooterBottom = styled.div`
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    height: 250px;
  }

  .copyright {
    color: ${props => props.theme.neutralGreen};
    opacity: 0.8;
    font-weight: 500px;
    margin: 0%;
  }

  @media (max-width: ${LG}) {
    flex-direction: column-reverse;
    align-items: flex-start;

    & > div {
      height: auto;
      width: 100%;
      padding-top: 50px;
    }
  }
`
export const TickLogo = styled.img`
  max-width: 106px;
  height: 87px;
  position: relative;
  top: 142px;
  right: 53px;

  @media (min-width: ${ONLY_HD}) {
    right: 220px;
  }

  @media (max-width: ${LG}) {
    top: unset;
    right: unset;
    left:-10px;
    margin-bottom:10px;
  }
`
