import styled from "styled-components"
import HamburgerMenu from "react-hamburger-menu"

export const Wrapper = styled.div``

export const ToggleMenu = styled.div`
  width: 64px;
  height: 64px;
  top: 0;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(97.08deg, #f38b97 20.01%, #f4904d 75.82%);
`

export const Hamburguer = styled(HamburgerMenu)`
  span {
    &:last-child {
      width: 50% !important;
      right: 0 !important;
    }
  }
`

export const MobileNav = styled.ul`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(93.28deg, #f38b97 20.01%, #f4904d 75.82%);
  padding-top: 30px;

  position: fixed;
  height: ${props => (props.isOpen ? "100vh" : "0")};
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  /*  height: 100vh; */
  z-index: 100;

  padding-left: 40px;
  transition: all ease-in 0.4s;
  overflow: scroll;

  li {
    margin-bottom: 30px;
    opacity: ${props => (props.isOpen ? "1" : "0")};
    transition: ${props => props.theme.defaultTransition()};
    a,
    button {
      font-weight: 500;
      font-size: 22px;
      line-height: 31px;
      color: white;
      background-color: transparent;

      border: 0;
    }
  }

  > div {
    position: relative;
    height: 100%;
  }
`
export const SubMenuWrapper = styled.div`
  padding-left: 40px;
  margin-left: -40px;
  padding-top: 10px;
  transition: all ease-in 0.4s;
  z-index: 2;
  position: relative;
  margin-bottom: -20px;

  button {
    z-index: 4;
    position: relative;
    margin-bottom: 20px;
  }

  img {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
  }
`

export const SubMenuBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => (props.isOpen ? "#003541;" : "transparent")};
  opacity: 0.1;
  z-index: 1;
  transition: ${props => props.theme.defaultTransition()};
`

export const LogoMobile = styled.img`
  width: 200px;
  margin: 30px 0 60px;

  opacity: ${props => (props.isOpen ? "1" : "0")};
  transition: ${props => props.theme.defaultTransition()};
`

export const Close = styled.img`
  width: 72px;
  height: 72px;
  position: absolute;
  right: 15px;
  top: 0;
  /*   left: 50%; */
  opacity: ${props => (props.isOpen ? "1" : "0")};
  /*  transition: all ease-in 0.4s;
  transition-delay: 0.7s;
  margin-bottom: 30px;
  transform: translate(-80%, 0);
  bottom: 5%; */
  z-index: 999;
`
