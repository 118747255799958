export const BREAKPOINT = "768px"
export const BREAKPOINT_TOPNAV = "1020px"
export const BREAKPOINT_CEL_ONLY = "767px"
export const XS = "374px"
export const LG = "992px"
export const XL = "1200px"
export const XXL = "1440px"
export const ONLY_HD = "1441px"


export const menuItems = [
  { label: "Home", path: "/" },
  { label: "Research Projects", path: "/research-projects" },
  {
    label: "Conference",
    path: "/upcoming-event",
    submenu: [
      { label: "Evento 1", path: "/evento-1" },
      { label: "Evento 2", path: "/evento-2" },
    ],
  },


  { label: "Consortium", path: "/consortium" },
  // { label: "Stories", path: "/stories" },
  { label: "Team", path: "/team" },
  { label: "About", path: "/about" },
]

export const socialLinks = [
  {
    label: "Facebook",
    path: "https://www.facebook.com/LymeMIND/",
    icon: "facebook",
  },
  {
    label: "Linked in",
    path: "https://www.linkedin.com/company/lymemind/",
    icon: "linkedin",
  },
  {
    label: "Twitter",
    path: "https://twitter.com/LymeMind",
    icon: "twitter",
  },
  {
    label: "Youtube",
    path: "https://www.youtube.com/channel/UCRUjdghBT0pyNSx14ukrKDw",
    icon: "youtube",
  },
]
