import styled from "styled-components"
import {LG} from "../../../utils/constants"

export const Wrapper = styled.div`
  width: ${props => (props.sizeItem ? "100%" : "50%")};
  display: ${props => (props.sizeItem ? "block" : "inline-block")};
  margin-bottom: 20px;

@media(max-width:${LG}){
  width:100%;
}


  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: ${props => props.theme.neutralDark};
    border-bottom: 1px solid rgba(176, 201, 203, 0.22);
    padding-bottom: 5px;
  }
  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: ${props => props.theme.lightBlue};
    padding-top: 5px;
  }
`
