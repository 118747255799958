import React, { useState, useEffect } from "react"
import { Wrapper, DropButton, Menu, Item } from "./style"
import onClickOutside from "react-onclickoutside"

const CustomSelect = ({
  SelectData,
  handleInputChange,
  name,
  selectedItem,
}) => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(0)
  const [initial, setInitial] = useState(false)

  const toggle = () => setOpen(!open)

  CustomSelect.handleClickOutside = () => setOpen(false)



  useEffect(() => {
    if (selected !== 0) {
      setInitial(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])



  return (
    <Wrapper
      open={open}
      initial={initial}
      selectValue={selected}
      onClick={toggle}
    >
      <span>{SelectData[selectedItem]}</span>
      <DropButton open={open} />
      <Menu open={open}>
        {SelectData.map((options, i) => (
          <Item
            type="text"
            placeholder="Search..."
            key={i}
            index={i}
            selectValue={selectedItem}
            onClick={e => {
              handleInputChange(e, i, 'customSelect')
              setSelected(i)
            }}
            value={options}
            name={name}
            onChange={e => handleInputChange(e, i)}
          />
        ))}
      </Menu>
    </Wrapper>
  )
}
CustomSelect.prototype = {}

const clickOutsideConfig = {
  handleClickOutside: () => CustomSelect.handleClickOutside,
}

export default onClickOutside(CustomSelect, clickOutsideConfig)
